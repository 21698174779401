import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import store from '@/store';
import pages from '@/router/pages';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = pages;

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
});

router.beforeEach((to, from, next) => {
	const isProjectLoaded = store.getters.projectIsLoaded;
	if (
		to.name !== 'projects' &&
		to.name !== 'info-index' &&
		to.name !== 'licenses' &&
		!isProjectLoaded
	)
		next({ name: 'projects' });
	else next();
});

export default router;
