import colors from '@/store/project-current/colors';
import { MarkType } from '@/types/sqlite';

export interface EditorContents {
	type: 'doc';
	content: ParagraphEditorContents[];
}

interface ParagraphEditorContents {
	type: 'paragraph';
	content?: (TextEditorContents | MarkEditorContents)[];
}

interface TextEditorContents {
	type: 'text';
	text: string;
}

interface MarkEditorContents {
	type: 'mark';
	attrs: { [key: string]: string | number };
}

export interface MarkForEditor {
	color: string;
	label: string;
	start: number;
	id: number;
}

export interface TipTapSelection {
	empty: boolean;
	$from: {
		parentOffset: number;
		parent: {
			content: { content: { type: { name: string } }[] };
		};
		doc: {
			content: { content: { textContent: string }[] };
		};
		index(number: number): number;
	};
	from: number;
	to: number;
}

interface RangeAndColor {
	from: number;
	to: number;
	color: string;
}

export interface EditorType {
	destroy(): void;
	commands: {
		highlight(rangeAndColor: RangeAndColor): void;
	};
	setContent(editorContents: EditorContents): void;
}

export function convertFileToEditor(
	fileContents: string,
	markings: MarkForEditor[]
): EditorContents {
	let lastMarkingIndex = 0;
	let lastParagraphsLength = 0;
	markings.sort((a, b) => a.start - b.start);
	function calculateParagraph(
		paragraphText: string
	): ParagraphEditorContents | {} {
		if (paragraphText === '') {
			lastParagraphsLength++;
			return {};
		}

		let currentParagraphPosition = 0;
		const currentParagraphLength = lastParagraphsLength + paragraphText.length;
		const content = [];
		while (
			lastMarkingIndex < markings.length &&
			markings[lastMarkingIndex].start <= currentParagraphLength
		) {
			if (
				markings[lastMarkingIndex].start ===
				lastParagraphsLength + currentParagraphPosition
			) {
				content.push({
					type: 'mark',
					attrs: {
						color: markings[lastMarkingIndex].color,
						label: markings[lastMarkingIndex].label,
						id: markings[lastMarkingIndex].id
					}
				});
				lastMarkingIndex++;
			} else {
				content.push({
					type: 'text',
					text: paragraphText.substring(
						currentParagraphPosition,
						markings[lastMarkingIndex].start - lastParagraphsLength
					)
				});
				currentParagraphPosition =
					markings[lastMarkingIndex].start - lastParagraphsLength;
			}
		}

		if (currentParagraphPosition !== paragraphText.length) {
			content.push({
				type: 'text',
				text: paragraphText.substring(
					currentParagraphPosition,
					paragraphText.length
				)
			});
		}

		lastParagraphsLength = currentParagraphLength + 1;
		return { content };
	}
	return {
		type: 'doc',
		content: fileContents.split('\n').map((v) => ({
			type: 'paragraph',
			...calculateParagraph(v)
		}))
	};
}

export function convertEditorToFile(
	editorContents: EditorContents
): string | undefined {
	return editorContents.content
		?.filter((v) => v.type === 'paragraph')
		.map((v) =>
			v.content
				? v.content.map((v) => ('text' in v ? v.text : '')).join('')
				: ''
		)
		.join('\n');
}

export function calculateSelection(selection: TipTapSelection) {
	const previousNodesTextSize = selection.$from.doc.content.content
		.slice(0, selection.$from.index(0))
		.reduce((p, v) => p + v.textContent.length + 1, 0);

	const nodeMarkingsCount = selection.$from.parent.content.content
		.slice(0, selection.$from.index(1))
		.reduce((p, v) => p + (v.type.name === 'mark' ? 1 : 0), 0);

	const realStartPosition =
		selection.$from.parentOffset - nodeMarkingsCount + previousNodesTextSize;

	const offset = selection.from - realStartPosition;

	return {
		start: selection.from - offset,
		end: selection.to - offset
	};
}

export function calculatePosition(
	range: { from: number; to: number },
	schema: EditorContents
) {
	let offsetFrom = 1;
	let offsetTo = 1;

	let currentPosition = 0;
	let nodes0Counter = 0;
	let nodes1Counter = 0;
	while (currentPosition < range.to && nodes0Counter < schema.content.length) {
		let hasContent = true;
		if (!schema.content[nodes0Counter].content) {
			hasContent = false;
		}
		const currentNode = hasContent
			? schema.content[nodes0Counter].content?.[nodes1Counter]
			: null;
		if (hasContent && currentNode && currentNode.type === 'mark') {
			if (currentPosition <= range.from) {
				offsetFrom++;
			}
			offsetTo++;
		}
		if (hasContent && currentNode && currentNode.type === 'text') {
			currentPosition += currentNode.text.length;
		}
		if (
			hasContent &&
			nodes1Counter < schema.content[nodes0Counter].content!.length - 1
		) {
			nodes1Counter++;
		} else {
			nodes0Counter++;
			nodes1Counter = 0;
			if (currentPosition < range.from) {
				offsetFrom += 1;
			}
			if (currentPosition < range.to) {
				offsetTo += 1;
			}
			currentPosition++;
		}
	}

	return {
		from: range.from + offsetFrom,
		to: range.to + offsetTo
	};
}

export function getMarkColorAndRange({
	mark,
	codesIndex,
	editorContents
}: {
	mark: MarkType;
	codesIndex: { [_: number]: number };
	editorContents: EditorContents;
}): RangeAndColor {
	const color = colors[codesIndex[(mark as MarkType).cid] % colors.length];
	return {
		...calculatePosition(
			{
				from: (mark as MarkType).selfirst,
				to: (mark as MarkType).selend
			},
			editorContents
		),
		color
	};
}
