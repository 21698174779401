/// <reference types="gapi" />
/// <reference types="gapi.auth2" />

import { Module } from 'vuex';

const gapiConfig = {
	apiKey: process.env.VUE_APP_GOOGLE_API_KEY,
	clientId: process.env.VUE_APP_GOOGLE_OAUTH_CLIENTID,
	discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'],
	scope:
		'https://www.googleapis.com/auth/drive.appdata https://www.googleapis.com/auth/drive.file'
};

const noCookiesIncognitoModeError = {
	error: 'idpiframe_initialization_failed',
	details: 'Cookies are not enabled in current environment.'
};

export interface AuthState {
	isAuthenticated: boolean;
	avatar: string | null;
	userName: string | null;
}

const authModule: Module<AuthState, {}> = {
	state: () => ({
		isAuthenticated: false,
		avatar: null,
		userName: null
	}),
	getters: {
		getUserPhoto(state) {
			return state.isAuthenticated ? state.avatar : '';
		}
	},
	mutations: {
		isAuthenticated(state, isAuthenticated) {
			state.isAuthenticated = isAuthenticated;
		},
		avatar(state, avatar) {
			state.avatar = avatar;
		},
		userName(state, userName) {
			state.userName = userName;
		}
	},
	actions: {
		login() {
			gapi.auth2.getAuthInstance().signIn();
		},
		logout() {
			gapi.auth2.getAuthInstance().signOut();
		},
		start({ commit, dispatch }) {
			const updateSigninStatus = (isAuthenticated: boolean) => {
				commit('isAuthenticated', isAuthenticated);
				if (isAuthenticated && gapi.auth2.getAuthInstance().isSignedIn.get()) {
					const profile = gapi.auth2
						.getAuthInstance()
						.currentUser.get()
						.getBasicProfile();
					commit('avatar', profile.getImageUrl());
					commit('userName', profile.getName());
				} else {
					commit('avatar', null);
					commit('userName', null);
				}
				dispatch('isAuthenticated', isAuthenticated);
			};
			const initClient = () => () => {
				gapi.client.init(gapiConfig).then(
					() => {
						gapi.auth2.getAuthInstance().isSignedIn.listen(updateSigninStatus);
						updateSigninStatus(gapi.auth2.getAuthInstance().isSignedIn.get());
					},
					(error) => {
						if (
							error.error === noCookiesIncognitoModeError.error &&
							error.details === noCookiesIncognitoModeError.details
						)
							return updateSigninStatus(false);

						console.error(JSON.stringify(error, null, 2));
					}
				);
			};
			gapi.load('client:auth2', initClient());
		}
	}
};

export default authModule;
