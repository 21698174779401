
















import Vue, { PropType } from 'vue';
import VMark from '@/components/mark.vue';
import { mapActions } from 'vuex';

interface TipTapNode {
	attrs: { [prop: string]: string };
}

export default Vue.extend({
	name: 'MarkInsideEditor',
	data(): { showFab: boolean } {
		return { showFab: false };
	},
	components: { VMark },
	props: {
		node: {
			type: Object as PropType<TipTapNode>
		}
	},
	computed: {
		color(): string {
			return this.node.attrs.color;
		},
		label(): string {
			return this.node.attrs.label;
		},
		id(): string {
			return this.node.attrs.id;
		}
	},
	methods: {
		...mapActions(['toggleMarkById', 'deleteMarkById'])
	}
});
