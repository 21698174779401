import { Module } from 'vuex';
import router from '@/router';
import { RootState } from '@/store';

export interface UiState {
	menu: boolean;
	routePath: string;
}

function replaceParams(path: string, params: object) {
	let pathWithParams = path;
	Object.entries(params).forEach(
		([key, value]) =>
			(pathWithParams = pathWithParams.replace(`:${key}`, value))
	);
	return pathWithParams;
}

function getText(breadCrumbs: string | Function, state: any) {
	return typeof breadCrumbs === 'function' ? breadCrumbs(state) : breadCrumbs;
}

const UiModule: Module<UiState, RootState> = {
	state: () => ({
		menu: false,
		routePath: '/'
	}),
	getters: {
		isRouteSelected: () => (route: any, path: string) => {
			return route.path === path || route.path.startsWith(`${path}/`);
		},
		isRouteDisabled: (state, getters, rootState, rootGetters) => (
			path: string
		) => {
			return (
				path !== '/projects' && path !== '/info' && !rootGetters.projectIsLoaded
			);
		},
		getBreadcrumbs: (state, getters, rootState, rootGetters) => {
			return [
				{
					text: 'Projects',
					disabled: rootState.route.name === 'projects',
					to: '/projects'
				},
				...(rootState.route.name === 'projects'
					? []
					: rootState.route.matched
							.filter((route) => Boolean(route.meta.breadCrumb))
							.map((route) => ({
								text: getText(route.meta.breadCrumb, { rootGetters }),
								to: replaceParams(route.path, rootState.route.params),
								exact: true
							})))
			];
		}
	},
	mutations: {
		toggleMenu(state) {
			state.menu = !state.menu;
		},
		routePath(state, routePath) {
			state.routePath = routePath;
		}
	},
	actions: {
		toggleMenu({ commit }) {
			commit('toggleMenu');
		},
		navigate({ commit }, path: string) {
			if (router.currentRoute.path !== path) {
				router.push(path);
				commit('routePath', path);
			}
		}
	}
};

export default UiModule;
