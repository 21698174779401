











import Vue from 'vue';
import AppBar from '@/components/app-bar.vue';
import Menu from '@/components/menu.vue';
import { mapActions } from 'vuex';
import Breadcrumbs from '@/components/breadcrumbs.vue';

export default Vue.extend({
	name: 'App',
	components: {
		Breadcrumbs,
		AppBar,
		Menu
	},
	mounted() {
		this.start();
	},
	methods: mapActions(['start', 'toggleMenu'])
});
