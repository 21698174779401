





























































































































































































import Vue from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import { RootState } from '@/store';
import NewProject from '@/components/new-project.vue';
import VMemo from '@/components/memo.vue';

export default Vue.extend({
	name: 'ProjectList',
	components: { NewProject, VMemo },
	data: () => ({ search: '', memoDialog: false, initialItem: {} }),
	computed: {
		...mapState({
			isAuthenticated: (state) => (state as RootState).auth.isAuthenticated,
			headers: (state) => (state as RootState).projectList.projects.headers,
			data: (state) => (state as RootState).projectList.projects.data,
			isLoading: (state) => (state as RootState).projectList.isLoading,
			isSaving: (state) => (state as RootState).projectList.isSaving
		}),
		...mapGetters(['projectIsLoaded', 'isRqdaFile', 'isOqdaFile'])
	},
	methods: mapActions([
		'uploadFileProject',
		'closeProject',
		'login',
		'logout',
		'deleteProject',
		'downloadProjectOqda',
		'downloadProjectRqda',
		'openProject',
		'cleanProject',
		'updateMemoProject'
	])
});
