




































































































import Vue from 'vue';
import { RootState } from '@/store';
import { mapActions, mapGetters, mapState } from 'vuex';
import { version } from '../../package.json';
import pages from '@/router/pages';
import { RouteConfig } from 'vue-router';

function convertPagesToMenuItems(pagesArr: Array<RouteConfig>) {
	return pagesArr
		.filter((v) => v.meta && v.meta.menuPosition)
		.sort((a, b) => a.meta.menuPosition - b.meta.menuPosition)
		.map((v) => ({
			text: v.meta.breadCrumb,
			to: v.path,
			icon: v.meta.icon,
			dataQa:
				'menu' +
				v.meta.breadCrumb
					.replace(/\b\w/g, (l: string) => l.toUpperCase())
					.replace(' ', '')
		}));
}

export default Vue.extend({
	name: 'Menu',

	data() {
		return {
			drawer: null,
			version
		};
	},
	methods: mapActions(['navigate', 'login', 'logout']),
	computed: {
		...mapState({
			menu: (state) => (state as RootState).ui.menu,
			isAuthenticated: (state) => (state as RootState).auth.isAuthenticated,
			userName: (state) => (state as RootState).auth.userName
		}),
		...mapGetters([
			'getUserPhoto',
			'isRouteSelected',
			'isRouteDisabled',
			'projectIsLoaded'
		]),
		menuItems: () => convertPagesToMenuItems(pages)
	}
});
