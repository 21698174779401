


















































import Vue from 'vue';
import { mapActions } from 'vuex';

export default Vue.extend({
	name: 'new-project',
	data: () => ({
		dialog: false,
		projectName: ''
	}),
	methods: {
		...mapActions(['newProject']),
		save() {
			this.newProject(this.projectName);
			this.projectName = '';
			this.dialog = false;
		}
	}
});
