import { Module } from 'vuex';
import { RootState } from '..';
import { logging } from '@/logging';
import { getMarkColorAndRange } from '@/store/text-editor/format-file';
import { MarkType } from '@/types/sqlite';
import {
	dbDeleteCodingByMark,
	dbUpdateCodingByMark
} from '@/store/shared/sqlite';
import { calculateChangedMarkings } from '@/store/marking/calculate-changed-markings';

const log = logging('store:marking:index');

export interface MarkingState {
	selectedMarkId: null | number;
}

const MarkingModule: Module<MarkingState, RootState> = {
	state: {
		selectedMarkId: null
	},
	mutations: {
		setSelectedMarkId(state, markId) {
			state.selectedMarkId = markId;
		}
	},
	actions: {
		createMark({ state, dispatch, rootState, rootGetters }, code) {
			log('createMark', { state, rootState });
			if (rootState.textEditor.selection !== null) {
				const file = rootGetters.getSelectedFile;
				const newFileCode = {
					cid: code.id,
					fid: file.id,
					seltext: file.file.substring(
						rootState.textEditor.selection.start,
						rootState.textEditor.selection.end
					),
					selfirst: rootState.textEditor.selection.start,
					selend: rootState.textEditor.selection.end,
					status: 1
				};
				dispatch('addMark', newFileCode);
			}
		},
		toggleMarkById({ state, dispatch }, id) {
			if (state.selectedMarkId !== null && state.selectedMarkId === id) {
				return dispatch('clearMarkIdAndHighlight');
			}

			dispatch('setMarkIdAndHighlight', id);
		},
		clearMarkIdAndHighlight({ commit, dispatch }) {
			commit('setSelectedMarkId', null);
			dispatch('setHighlight', null);
		},
		setMarkIdAndHighlight({ commit, dispatch, rootGetters, rootState }, id) {
			commit('setSelectedMarkId', id);
			const mark = rootGetters.getMarkById(id);
			const editorContents = rootGetters.getEditor.getJSON();
			const rangeAndColor = getMarkColorAndRange({
				mark,
				codesIndex: rootState.projectCurrent.codesIndex,
				editorContents
			});
			dispatch('setHighlight', rangeAndColor);
		},
		updateMarkingsWithNewText({ rootGetters, dispatch }, newText) {
			const file = rootGetters.getSelectedFile;
			const markings = rootGetters.getMarksForCurrentFile;
			const markingsChange = calculateChangedMarkings(file, newText, markings);
			dispatch('removeMarkings', markingsChange.removed);
			dispatch('updateMarkings', markingsChange.updated);
		},
		removeMarkings({ commit, dispatch }, markings: MarkType[]) {
			markings.forEach((v) => {
				dbDeleteCodingByMark(v);
				commit('removeFileCodeByMark', v);
			});
			dispatch('updateOpenProjectData');
		},
		updateMarkings({ commit, dispatch, state }, markings: MarkType[][]) {
			markings.forEach(([previousValue, newValue]) => {
				dbUpdateCodingByMark(previousValue, newValue);
				commit('updateFileCodeByMark', [previousValue, newValue]);
				if (previousValue.id === state.selectedMarkId)
					dispatch('setMarkIdAndHighlight', previousValue.id);
			});
			dispatch('updateOpenProjectData');
		}
	}
};

export default MarkingModule;
