import { Node } from 'tiptap';
import MarkInsideEditor from '@/store/text-editor/mark-inside-editor.vue';
import { MarkForEditor } from '@/store/text-editor/format-file';

export default class MarkEditorExtension extends Node {
	get name() {
		return 'mark';
	}

	get schema() {
		return {
			inline: true,
			attrs: {
				color: {},
				label: {},
				id: {}
			},
			group: 'inline',
			selectable: false,
			parseDOM: [
				{
					tag: 'mark',
					getAttrs: (dom: any) => ({
						color: dom.getAttribute('data-color'),
						label: dom.getAttribute('data-label'),
						id: parseInt(dom.getAttribute('data-id'))
					})
				}
			],
			toDOM: (node: { attrs: MarkForEditor }) => [
				'mark',
				{
					'data-color': node.attrs.color,
					'data-label': node.attrs.label,
					'data-id': node.attrs.id
				}
			]
		};
	}

	get view() {
		return MarkInsideEditor;
	}
}
