<template>
	<v-breadcrumbs :items="getBreadcrumbs">
		<template v-slot:divider>
			<v-icon>chevron_right</v-icon>
		</template>
	</v-breadcrumbs>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
	name: 'Breadcrumbs',
	computed: mapGetters(['getBreadcrumbs'])
});
</script>

<style scoped></style>
