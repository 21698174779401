/// <reference types="gapi.client.drive" />

import { FileItem } from '@/store/project-list/index';

export async function getFiles(): Promise<
	gapi.client.drive.File[] | undefined
> {
	const files = [];
	let nextPageToken: string | null = null;
	do {
		const filesPaginated: gapi.client.drive.FileList = await gapi.client.drive.files
			.list({
				spaces: 'appDataFolder',
				fields: 'nextPageToken, files(id, name)',
				pageSize: 100,
				...(nextPageToken ? { pageToken: nextPageToken } : {})
			})
			.then((response) => {
				return response.result;
			});
		if (filesPaginated.files) files.push(...filesPaginated.files);
		nextPageToken = filesPaginated.nextPageToken!;
	} while (nextPageToken);
	return files;
}

export async function saveFile(file: Blob, fileName: string) {
	const metadata = {
		name: fileName,
		mimeType: file.type,
		parents: ['appDataFolder']
	};
	function createBlobFromObject(dataObject: {}) {
		return new Blob([JSON.stringify(dataObject, null, 2)], {
			type: 'application/json'
		});
	}

	const form = new FormData();
	form.append('metadata', createBlobFromObject(metadata));
	form.append('file', file);

	return fetch(
		`https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart`,
		{
			method: 'POST',
			headers: new Headers({
				Authorization: 'Bearer ' + gapi.auth.getToken().access_token
			}),
			body: form
		}
	)
		.then((response) => response.json())
		.then((v) => {
			return v.id;
		})
		.catch((err) => console.log({ err }));
}

export async function deleteFile(fileId: string) {
	return gapi.client.drive.files.delete({ fileId });
}

export async function getFile(fileId: string) {
	const response = await gapi.client.drive.files.get({
		fileId,
		alt: 'media'
	});
	if (response.status === 200) {
		return response;
	}
}

export async function updateFile(file: Blob, fileItem: FileItem) {
	const metadata = {
		name: fileItem.name
	};
	function createBlobFromObject(dataObject: {}) {
		return new Blob([JSON.stringify(dataObject, null, 2)], {
			type: 'application/json'
		});
	}

	const form = new FormData();
	form.append('metadata', createBlobFromObject(metadata));
	form.append('file', file);

	return fetch(
		`https://www.googleapis.com/upload/drive/v3/files/${fileItem.id}?uploadType=multipart`,
		{
			method: 'PATCH',
			headers: new Headers({
				Authorization: 'Bearer ' + gapi.auth.getToken().access_token
			}),
			body: form
		}
	)
		.then((response) => response.json())
		.then((v) => {
			return v.id;
		})
		.catch((err) => console.log({ err }));
}
