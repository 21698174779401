import { Module } from 'vuex';
import { RootState } from '@/store';
import { Editor } from 'tiptap';
import {
	calculateSelection,
	convertEditorToFile,
	convertFileToEditor,
	EditorContents,
	EditorType,
	getMarkColorAndRange,
	MarkForEditor,
	TipTapSelection
} from '@/store/text-editor/format-file';
import { MarkType } from '@/types/sqlite';
import colors from '@/store/project-current/colors';
import MarkEditorExtension from '@/store/text-editor/mark-editor-extension';
import HighlightEditorExtension from '@/store/text-editor/highlight-editor-extension';
import { logging } from '@/logging';

const log = logging('store:text-editor:index');

export interface TextEditorState {
	editor: EditorType | null;
	selection: { start: number; end: number } | null;
}

const TextEditorModule: Module<TextEditorState, RootState> = {
	state: { editor: null, selection: null },
	mutations: {
		createEditor(state, { dispatch }) {
			if (state.editor !== null)
				console.error('Tried to create editor but it is already created.');
			state.editor = new Editor({
				extensions: [new MarkEditorExtension(), new HighlightEditorExtension()],
				content: '',
				onTransaction: (args: { state: { selection: TipTapSelection } }) => {
					const selection = args.state.selection;
					if (selection.empty) {
						state.selection = null;
						return;
					}
					state.selection = calculateSelection(selection);
				},
				onUpdate: ({ getJSON }: { getJSON: () => EditorContents }) => {
					const editorContent = getJSON();
					const fileContents = convertEditorToFile(editorContent);
					dispatch('updateMarkingsWithNewText', fileContents);
					dispatch('updateCurrentFileContents', fileContents);
				}
			});
		},
		createJournalEditor(state, { dispatch }) {
			if (state.editor !== null)
				console.error('Tried to create editor but it is already created.');
			state.editor = new Editor({
				content: '',
				onUpdate: ({ getJSON }: { getJSON: () => EditorContents }) => {
					const editorContent = getJSON();
					const journalContents = convertEditorToFile(editorContent);
					dispatch('updateCurrentJournalContents', journalContents);
				}
			});
		},
		destroyEditor(state) {
			if (state.editor === null) return;
			state.editor.destroy();
			state.editor = null;
		}
	},
	getters: {
		getEditor: (state) => state.editor
	},
	actions: {
		destroyEditor: ({ commit }) => {
			commit('destroyEditor');
		},
		createEditor: async ({ commit, dispatch }) => {
			commit('createEditor', { dispatch });
			await dispatch('updateEditor');
		},
		createJournalEditor: async ({ commit, dispatch }) => {
			commit('createJournalEditor', { dispatch });
			await dispatch('updateJournalEditor');
		},
		setHighlight: ({ state }, rangeAndColor) => {
			state.editor?.commands.highlight(rangeAndColor);
		},
		updateEditor: ({ state, rootGetters, rootState, dispatch }) => {
			const markIsSelected = rootState.marking.selectedMarkId !== null;
			let mark: MarkType | null = null;
			// TODO move color label index to an object that can be accessed everywhere instead of getting those info everytime
			const markings: MarkForEditor[] = (rootGetters.getMarksForCurrentFile as MarkType[]).map(
				(v, index) => {
					if (markIsSelected && v.id === rootState.marking.selectedMarkId) {
						mark = v;
					}
					return {
						...v,
						label: rootGetters.getCodeNameById(v.cid),
						start: v.selfirst,
						color:
							colors[
								rootState.projectCurrent.codesIndex[v.cid] % colors.length
							],
						index
					};
				}
			);

			log('file and markings', {
				file: rootGetters.getSelectedFile.file,
				markings
			});

			const editorContents = convertFileToEditor(
				rootGetters.getSelectedFile.file,
				markings
			);

			state.editor?.setContent(editorContents);

			// update highlight TODO: this will be not needed if markings where not tags but decorations

			if (markIsSelected && mark === null)
				return dispatch('clearMarkIdAndHighlight');
			if (markIsSelected && mark !== null) {
				const rangeAndColor = getMarkColorAndRange({
					mark,
					codesIndex: rootState.projectCurrent.codesIndex,
					editorContents
				});

				return dispatch('setHighlight', rangeAndColor);
			}
		},
		updateJournalEditor: ({ state, rootGetters }) => {
			const editorContents = convertFileToEditor(
				rootGetters.getSelectedJournal.journal,
				[]
			);

			state.editor?.setContent(editorContents);
		}
	}
};

export default TextEditorModule;
