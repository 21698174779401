import { Extension, Plugin } from 'tiptap';
import { Decoration, DecorationSet } from 'prosemirror-view';

export default class HighlightEditorExtension extends Extension {
	constructor(options = {}) {
		super(options);

		this.highlightRange = null;
		this._updating = false;
	}

	get name() {
		return 'highlight';
	}

	get defaultOptions() {
		return {
			className: 'active',
			highlighting: false
		};
	}

	commands() {
		return {
			highlight: (attrs: any) => this.highlight(attrs),
			clear: () => this.clear()
		};
	}

	get decorations() {
		if (this.highlightRange === null) return [];
		return [
			Decoration.inline(this.highlightRange.from, this.highlightRange.to, {
				class: this.options.className,
				style: `color: ${this.highlightRange.color}`
			})
		];
	}

	highlight(range: any) {
		return (state: any, dispatch: any) => {
			this.highlightRange = range;

			this.updateView(state, dispatch);
		};
	}

	clear() {
		return (state: any, dispatch: any) => {
			this.highlightRange = null;

			this.updateView(state, dispatch);
		};
	}

	updateView({ tr }: any, dispatch: any) {
		this._updating = true;
		dispatch(tr);
		this._updating = false;
	}

	createDeco(doc: any) {
		return this.decorations ? DecorationSet.create(doc, this.decorations) : [];
	}

	get plugins() {
		return [
			new Plugin({
				state: {
					init() {
						return DecorationSet.empty;
					},
					apply: (tr: any, set: any): any => {
						// console.log('plugin', this, this.createDeco);
						// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
						// @ts-ignore
						return this.createDeco(tr.doc);
						// return set.map(tr.mapping, tr.doc);
					}
				},
				props: {
					decorations(state: any): any {
						// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
						// @ts-ignore
						return this.getState(state);
					}
				}
			})
		];
	}
}
