import ProjectList from '@/views/project-list.vue';

const pages = [
	{
		path: '/',
		redirect: { name: 'projects' }
	},
	{
		path: '/projects',
		name: 'projects',
		meta: { breadCrumb: 'Projects', icon: '$folderOpen', menuPosition: 1 },
		component: ProjectList
	},
	{
		path: '/files',
		name: 'files',
		meta: { breadCrumb: 'Files', icon: '$contentCopy', menuPosition: 2 },
		component: () =>
			import(
				/* webpackChunkName: "router-view-wrapper" */ '../views/router-view-wrapper.vue'
			),
		children: [
			{
				path: '',
				name: 'file-list-index',
				component: () =>
					import(
						/* webpackChunkName: "file-list-index" */ '../views/file-list.vue'
					)
			},
			{
				path: ':fileId',
				name: 'file',
				meta: {
					breadCrumb: ({ rootGetters }: { rootGetters: any }) =>
						rootGetters.getSelectedFile.name
				},
				component: () =>
					import(/* webpackChunkName: "file" */ '../views/file.vue')
			}
		]
	},
	{
		path: '/markings',
		name: 'markings',
		meta: {
			breadCrumb: 'Marked codes',
			icon: '$commentBank',
			menuPosition: 3
		},
		component: () =>
			import(/* webpackChunkName: "mark-list" */ '../views/mark-list.vue')
	},
	{
		path: '/categories',
		name: 'categories',
		meta: { breadCrumb: 'Categories', icon: '$category', menuPosition: 4 },
		component: () =>
			import(
				/* webpackChunkName: "category-list" */ '../views/category-list.vue'
			)
	},
	{
		path: '/categories-tree',
		name: 'categories-tree',
		meta: {
			breadCrumb: 'Categories Tree View',
			icon: '$park',
			menuPosition: 5
		},
		component: () =>
			import(
				/* webpackChunkName: "category-tree" */ '../views/category-tree.vue'
			)
	},
	{
		path: '/file-categories',
		name: 'file-categories',
		meta: { breadCrumb: 'File categories', icon: '$noteAdd', menuPosition: 6 },
		component: () =>
			import(
				/* webpackChunkName: "file-category-list" */ '../views/file-category-list.vue'
			)
	},
	{
		path: '/cases',
		name: 'cases',
		meta: { breadCrumb: 'Cases', icon: '$work', menuPosition: 7 },
		component: () =>
			import(/* webpackChunkName: "case-list" */ '../views/case-list.vue')
	},
	{
		path: '/cases-profile',
		name: 'cases-profile',
		meta: { breadCrumb: 'Cases profile', icon: '$book', menuPosition: 8 },
		component: () =>
			import(/* webpackChunkName: "case-profile" */ '../views/case-profile.vue')
	},
	{
		path: '/journals',
		name: 'journals',
		meta: { breadCrumb: 'Journals', icon: '$menuBook', menuPosition: 9 },
		component: () =>
			import(
				/* webpackChunkName: "router-view-wrapper" */ '../views/router-view-wrapper.vue'
			),
		children: [
			{
				path: '',
				name: 'journal-list-index',
				component: () =>
					import(
						/* webpackChunkName: "journal-list-index" */ '../views/journal-list.vue'
					)
			},
			{
				path: ':journalId',
				name: 'journal',
				meta: {
					breadCrumb: ({ rootGetters }: { rootGetters: any }) =>
						rootGetters.getSelectedJournal.name
				},
				component: () =>
					import(/* webpackChunkName: "journal" */ '../views/journal.vue')
			}
		]
	},
	{
		path: '/graph',
		name: 'graph',
		meta: { breadCrumb: 'Graph', icon: '$share', menuPosition: 10 },
		component: () =>
			import(/* webpackChunkName: "graph" */ '../views/graph.vue')
	},
	{
		path: '/data',
		name: 'data',
		meta: {
			breadCrumb: 'Project data',
			icon: '$description',
			menuPosition: 11
		},
		component: () => import(/* webpackChunkName: "data" */ '../views/data.vue')
	},
	{
		path: '/info',
		name: 'info',
		meta: { breadCrumb: 'Information', icon: '$info', menuPosition: 12 },
		component: () =>
			import(/* webpackChunkName: "info" */ '../views/router-view-wrapper.vue'),
		children: [
			{
				path: '',
				name: 'info-index',
				component: () =>
					import(/* webpackChunkName: "file-list-index" */ '../views/info.vue')
			},
			{
				path: 'licenses',
				name: 'licenses',
				meta: {
					breadCrumb: 'Licenses'
				},
				component: () =>
					import(/* webpackChunkName: "licenses" */ '../views/licenses.vue')
			}
		]
	}
	// {
	// 	path: '/codes',
	// 	name: 'codes',
	// 	meta: { breadCrumb: 'Codes' },
	// 	component: () =>
	// 		import(/* webpackChunkName: "code-list" */ '../views/code-list.vue')
	// },
];

export default pages;
