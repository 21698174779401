var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"projects"},[_c('input',{ref:"file",attrs:{"type":"file","accept":".rqda,.oqda"},on:{"change":function($event){return _vm.uploadFileProject(_vm.$refs.file.files[0])}}}),_c('v-card',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.data,"loading":_vm.isLoading,"search":_vm.search,"hide-default-footer":"","items-per-page":-1,"data-qa":"projectList"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"mr-3"},[_vm._v("Projects")]),_c('new-project'),_c('v-btn',{staticClass:"ml-3",attrs:{"color":"primary","data-qa":"importRQDAProject"},on:{"click":function($event){$event.stopPropagation();return _vm.$refs.file.click()}}},[_vm._v(" Upload file ")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"search","label":"Search","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"no-data",fn:function(){return [_c('span',[_vm._v("No project files")])]},proxy:true},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.isLocal)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"dark":""}},'v-icon',attrs,false),on),[_vm._v(" $cloudOff ")])]}}],null,true)},[_c('span',[_vm._v("Not saved")])]):_vm._e(),(!item.isLocal && (!item.isOpen || (item.isOpen && !_vm.isSaving)))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"}},'v-icon',attrs,false),on),[_vm._v(" $cloudOn ")])]}}],null,true)},[_c('span',[_vm._v("Saved")])]):_vm._e(),(item.isOpen && _vm.isSaving)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"dark":""}},'v-icon',attrs,false),on),[_vm._v(" $cloudUpload ")])]}}],null,true)},[_c('span',[_vm._v("Saving...")])]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(!item.isOpen && _vm.isOqdaFile(item.name))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.openProject(item)}}},'v-icon',attrs,false),on),[_vm._v(" $findInPage ")])]}}],null,true)},[_c('span',[_vm._v("Open")])]):_vm._e(),(item.isOpen && _vm.isOqdaFile(item.name))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","dark":""},on:{"click":function($event){_vm.initialItem = Object.assign({}, item);
								_vm.memoDialog = true;}}},'v-icon',attrs,false),on),[_vm._v(" $assignment ")])]}}],null,true)},[_c('span',[_vm._v("Memo")])]):_vm._e(),(item.isOpen)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
								var on = ref.on;
								var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.closeProject()}}},'v-icon',attrs,false),on),[_vm._v(" $close ")])]}}],null,true)},[_c('span',[_vm._v("Close")])]):_vm._e(),(_vm.isOqdaFile(item.name))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
								var on = ref.on;
								var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.downloadProjectOqda(item)}}},'v-icon',attrs,false),on),[_vm._v(" $fileOqda ")])]}}],null,true)},[_c('span',[_vm._v("Download OQDA file")])]):_vm._e(),(_vm.isOqdaFile(item.name) || _vm.isRqdaFile(item.name))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
								var on = ref.on;
								var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"},on:{"click":function($event){return _vm.downloadProjectRqda(item)}}},'v-icon',attrs,false),on),[_vm._v(" $fileRqda ")])]}}],null,true)},[_c('span',[_vm._v("Download RQDA file")])]):_vm._e(),(!item.isLocal)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
								var on = ref.on;
								var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"},on:{"click":function($event){return _vm.deleteProject(item)}}},'v-icon',attrs,false),on),[_vm._v(" $delete ")])]}}],null,true)},[_c('span',[_vm._v("Delete")])]):_vm._e(),(_vm.isOqdaFile(item.name))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
								var on = ref.on;
								var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"},on:{"click":function($event){return _vm.cleanProject(item)}}},'v-icon',attrs,false),on),[_vm._v(" $cleaningServices ")])]}}],null,true)},[_c('span',[_vm._v("Clean")])]):_vm._e()]}}],null,true)}),_c('v-memo',{attrs:{"dialog":_vm.memoDialog,"initialItem":_vm.initialItem},on:{"save":function($event){_vm.updateMemoProject($event);
				_vm.memoDialog = false;},"cancel":function($event){_vm.memoDialog = false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }