export function downloadFile(file: Blob, fileName: string) {
	const a = document.createElement('a');
	document.body.appendChild(a);
	a.style.setProperty('display', 'none');
	const url = window.URL.createObjectURL(file);
	a.href = url;
	a.download = fileName;
	a.click();
	window.URL.revokeObjectURL(url);
	a.remove();
}

export function convertGapiFileToBlob(
	gapiFileData: gapi.client.Response<gapi.client.drive.File> | undefined
) {
	if (gapiFileData && gapiFileData.body) {
		const length = gapiFileData.body.length;
		const array = new Uint8Array(length);
		for (let i = 0; i < length; i++) {
			array[i] = gapiFileData.body.charCodeAt(i);
		}
		return new Blob([array], {
			type: 'application/x-sqlite3'
		});
	}
	return null;
}
