import Debug from 'debug';
const debug = Debug(`oqda`);
debug.log = console.log.bind(console);

export function logging(title: string) {
	const log = debug.extend(title);
	return function(arg: any, ...args: any[]) {
		log(arg, ...args);
	};
}
