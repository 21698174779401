import initSqlJs from 'sql.js';
import { SqlJs } from 'sql.js/module';
import {
	CaseType,
	JournalType,
	LinkCaseFileType,
	MarkType
} from '@/types/sqlite';
import {
	updateCategoryTablesWithoutParentId,
	updateCategoryTablesWithParentId,
	updateCodingTablesWithId,
	updateCodingTablesWithoutId
} from '@/store/shared/versioning';

// region Common

let db: SqlJs.Database | null = null;

interface TableData {
	headers: { text: string; value: string }[];
	data: { [prop: string]: string | number }[];
}

function getTableData(res: SqlJs.QueryResults[]) {
	const tableData = { headers: [], data: [] } as TableData;
	if (res.length === 0) return tableData;
	tableData.headers = res[0].columns.map((v: string) => ({
		text: v,
		value: v
	}));

	tableData.data = res[0].values.map((v: SqlJs.ValueType[]) =>
		res[0].columns.reduce(
			(p: {}, c: string, i: number) => ({ ...p, [c]: v[i] }),
			{}
		)
	);
	return tableData;
}

export function dateNow(date: Date = new Date()) {
	const [weekDay, month, day, year, hour] = date.toString().split(' ', 5); // ["Sat", "Oct", "03", "2020", "22:54:12"]
	return `${weekDay} ${month} ${day} ${hour} ${year}`; // Fri Aug 14 11:26:03 2020
}

export function dateShort(date: Date = new Date()) {
	const [_, __, day, year, hour] = date.toString().split(' ', 5); // ["Sat", "Oct", "03", "2020", "22:54:12"]
	const month = `0${date.getMonth() + 1}`.substr(-2);
	return `${year}-${month}-${day} ${hour}`; // 2021-03-07 10:53:39
}

// endregion

export async function loadDataFromFile(
	file: File
): Promise<{ [propName: string]: {} | [] }> {
	const r = new FileReader();
	const result: Promise<{ [propName: string]: {} | [] }> = new Promise(
		(resolve) => {
			r.onload = async function() {
				const Uints = new Uint8Array(r.result as ArrayBuffer);
				const SQL = await initSqlJs();
				db = new SQL.Database(Uints);

				let res = db.exec('SELECT * FROM freecode');
				const codes = getTableData(res);

				res = db.exec('SELECT * FROM codecat');
				let categories = getTableData(res);
				if (!categories.headers.find((v) => v.value === 'parentid'))
					updateCategoryTablesWithParentId(db);
				res = db.exec('SELECT * FROM codecat');
				categories = getTableData(res);

				res = db.exec('SELECT * FROM source');
				const sources = getTableData(res);

				res = db.exec('SELECT * FROM filecat');
				const fileCategories = getTableData(res);

				res = db.exec('SELECT * FROM coding');
				let fileCodes = getTableData(res);
				if (!fileCodes.headers.find((v) => v.value === 'id'))
					updateCodingTablesWithId(db);
				res = db.exec('SELECT * FROM coding');
				fileCodes = getTableData(res);

				res = db.exec('SELECT * FROM cases');
				const cases = getTableData(res);

				res = db.exec('SELECT * FROM treecode');
				const { data: linksRaw } = getTableData(res);

				res = db.exec('SELECT * FROM treefile');
				const { data: linksFile } = getTableData(res);

				res = db.exec('SELECT * FROM caselinkage');
				const { data: linksCase } = getTableData(res);

				res = db.exec('SELECT * FROM journal');
				const journals = getTableData(res);

				const filesIndex = sources.data.reduce(
					(p, c, i) => ({
						...p,
						[c.id]: i
					}),
					{}
				);
				const codesIndex = codes.data.reduce(
					(p, c, i) => ({
						...p,
						[c.id]: i
					}),
					{}
				);

				resolve({
					codes,
					categories,
					sources,
					fileCategories,
					fileCodes,
					linksRaw,
					codesIndex,
					filesIndex,
					linksFile,
					cases,
					linksCase,
					journals
				});
			};
		}
	);
	r.readAsArrayBuffer(file);
	return result;
}

export function dbExport() {
	const data = db?.export();
	if (!data) return null;
	return new Blob([data], {
		type: 'application/x-sqlite3'
	});
}

export function dbClean() {
	if (!db) return;
	db.run(`
		DELETE FROM freecode WHERE status = 0;
		DELETE FROM codecat WHERE status = 0;
		DELETE FROM source WHERE status = 0;
		DELETE FROM filecat WHERE status = 0;
		DELETE FROM coding WHERE status = 0;
		DELETE FROM cases WHERE status = 0;
		DELETE FROM treecode WHERE status = 0;
		DELETE FROM treefile WHERE status = 0;
		DELETE FROM caselinkage WHERE status = 0;	
	`);
}

export async function convertDataToRQDA(file: Blob): Promise<Blob | null> {
	const r = new FileReader();
	const result: Promise<Blob | null> = new Promise((resolve) => {
		r.onload = async function() {
			const Uints = new Uint8Array(r.result as ArrayBuffer);
			const SQL = await initSqlJs();
			const conversionDB = new SQL.Database(Uints);
			updateCodingTablesWithoutId(conversionDB);
			updateCategoryTablesWithoutParentId(conversionDB);
			const data = conversionDB?.export();
			if (!data) return resolve(null);

			resolve(
				new Blob([data], {
					type: 'application/x-sqlite3'
				})
			);
		};
	});
	r.readAsArrayBuffer(file);
	return result;
}

// endregion

// region Marking

export function dbInsertCoding(coding: MarkType) {
	db!.run(
		`
		INSERT INTO coding 
		(cid, fid, seltext, selfirst, selend, status, owner, date) 
		VALUES
		(?, ?, ?, ?, ?, ?,'oqda', ?)`,
		[
			coding.cid,
			coding.fid,
			coding.seltext,
			coding.selfirst,
			coding.selend,
			coding.status,
			dateNow()
		]
	);
	const res = db!.exec('SELECT last_insert_rowid()');
	return res[0].values[0][0];
}

export function dbDeleteCodingByMark(marking: MarkType) {
	db!.run(
		`
		UPDATE coding 
		SET status = 0
		WHERE cid = ?
		AND selfirst = ?
		AND selend = ?
		AND status = 1`,
		[marking.cid, marking.selfirst, marking.selend]
	);
}

export function dbUpdateCodingByMark(
	previousMark: MarkType,
	newMark: MarkType
) {
	db!.run(
		`
		UPDATE coding 
		SET selfirst = ?,
        selend = ?,
		    seltext = ?
		WHERE cid = ?
		AND selfirst = ?
		AND selend = ?
		AND status = 1`,
		[
			newMark.selfirst,
			newMark.selend,
			newMark.seltext,
			previousMark.cid,
			previousMark.selfirst,
			previousMark.selend
		]
	);
}

// endregion

// region Code

export function dbInsertCode(code: { [propName: string]: string | number }) {
	db!.run(
		`
		INSERT INTO freecode 
		(name, status, id, owner, date) 
		VALUES
		(?, ?, ?, 'oqda', ?)`,
		[code.name, code.status, code.id, dateNow()] // TODO: owner and date are only added to the db and not to the app data, so these fields are empty until next file load
	);
}

export function dbUpdateCode(code: { [propName: string]: string | number }) {
	db!.run(
		`
		UPDATE freecode 
		set name = ?
		WHERE id = ?`,
		[code.name, code.id]
	);
}

export function dbDeleteCode(code: { [propName: string]: string | number }) {
	// code
	db!.run(
		`
		UPDATE freecode 
		SET status = 0
		WHERE id = ?`,
		[code.id]
	);
	// marking
	db!.run(
		`
		UPDATE coding 
		SET status = 0
		WHERE cid = ?`,
		[code.id]
	);
	// code inside category
	db!.run(
		`
		UPDATE treecode 
		SET status = 0
		WHERE cid = ?`,
		[code.id]
	);
}

// endregion

// region Category

export function dbInsertCategory(category: {
	[propName: string]: string | number;
}) {
	db!.run(
		`
		INSERT INTO codecat 
		(name, parentid, status, catid, owner, date) 
		VALUES
		(?, ?, ?, ?, 'oqda', ?)`,
		[
			category.name,
			category.parentid,
			category.status,
			category.catid,
			dateNow()
		]
	);
}

export function dbUpdateCategory(category: {
	[propName: string]: string | number;
}) {
	db!.run(
		`
		UPDATE codecat 
		set name = ?,
		parentid = ?
		WHERE catid = ?`,
		[category.name, category.parentid, category.catid]
	);
}

export function dbDeleteCategory(category: {
	[propName: string]: string | number;
}) {
	// category
	db!.run(
		`
		UPDATE codecat 
		SET status = 0
		WHERE catid = ?`,
		[category.catid]
	);
	// code inside category
	db!.run(
		`
		UPDATE treecode 
		SET status = 0
		WHERE catid = ?`,
		[category.catid]
	);
}

// endregion

// region Category Code

export function dbInsertCategoryCode(item: {
	[propName: string]: string | number;
}) {
	db!.run(
		`
		INSERT INTO treecode 
		(cid, catid, status, owner, date) 
		VALUES
		(?, ?, ?, 'oqda', ?)`,
		[item.cid, item.catid, item.status, dateNow()]
	);
}

export function dbDeleteCategoryCode(item: {
	[propName: string]: string | number;
}) {
	db!.run(
		`
		UPDATE treecode 
		SET status = 0
		WHERE cid = ?
		AND catid = ?
		AND status = 1`,
		[item.cid, item.catid]
	);
}

// endregion

// region Project

export function dbInsertProject() {
	db!.run(
		`
		INSERT INTO project 
		(databaseversion, about, date) 
		VALUES
		('0.2.2', 'Database created by OQDA (https://oqda.piebox.net/)', ?)`,
		[dateNow()]
	);
}

// region File

export function dbInsertFile(item: { [propName: string]: string | number }) {
	db!.run(
		`
		INSERT INTO source 
		(id, name, status, file, owner, date) 
		VALUES
		(?, ?, ?, ?, 'oqda', ?)`,
		[item.id, item.name, item.status, item.file, dateNow()]
	);
}

export function dbUpdateFile(item: { [propName: string]: string | number }) {
	db!.run(
		`
		UPDATE source 
		set name = ?
		WHERE id = ?`,
		[item.name, item.id]
	);
}

export function dbUpdateFileContents(item: {
	[propName: string]: string | number;
}) {
	db!.run(
		`
		UPDATE source 
		set file = ?
		WHERE id = ?`,
		[item.file, item.id]
	);
}

export function dbDeleteFile(item: { [propName: string]: string | number }) {
	// file
	db!.run(
		`
		UPDATE source 
		SET status = 0
		WHERE id = ?`,
		[item.id]
	);

	// markings
	db!.run(
		`
		UPDATE coding 
		SET status = 0
		WHERE fid = ?`,
		[item.id]
	);

	// linksFile
	db!.run(
		`
		UPDATE treefile 
		SET status = 0
		WHERE fid = ?`,
		[item.id]
	);

	// file cat
}

// endregion

// region File Category

export function dbInsertFileCategory(item: {
	[propName: string]: string | number;
}) {
	db!.run(
		`
		INSERT INTO filecat 
		(name, status, catid, owner, date) 
		VALUES
		(?, ?, ?, 'oqda', ?)`,
		[item.name, item.status, item.catid, dateNow()]
	);
}

export function dbUpdateFileCategory(item: {
	[propName: string]: string | number;
}) {
	db!.run(
		`
		UPDATE filecat 
		set name = ?
		WHERE catid = ?`,
		[item.name, item.catid]
	);
}

export function dbDeleteFileCategory(item: {
	[propName: string]: string | number;
}) {
	// file category
	db!.run(
		`
		UPDATE filecat 
		SET status = 0
		WHERE catid = ?`,
		[item.catid]
	);
	// file category and file relationship
	db!.run(
		`
		UPDATE treefile 
		SET status = 0
		WHERE catid = ?`,
		[item.catid]
	);
}

// endregion

// region File Category with File

export function dbInsertFileCategoryFile(item: {
	[propName: string]: string | number;
}) {
	db!.run(
		`
		INSERT INTO treefile 
		(fid, catid, status, owner, date) 
		VALUES
		(?, ?, ?, 'oqda', ?)`,
		[item.fid, item.catid, item.status, dateNow()]
	);
}

export function dbDeleteFileCategoryFile(item: {
	[propName: string]: string | number;
}) {
	db!.run(
		`
		UPDATE treefile 
		SET status = 0
		WHERE fid = ?
		AND catid = ?
		AND status = 1`,
		[item.fid, item.catid]
	);
}

// endregion

// region Case

export function dbInsertCase(item: CaseType) {
	db!.run(
		`
		INSERT INTO cases 
		(name, status, id, owner, date) 
		VALUES
		(?, ?, ?, 'oqda', ?)`,
		[item.name, item.status, item.id, dateNow()]
	);
}

export function dbUpdateCase(item: CaseType) {
	db!.run(
		`
		UPDATE cases 
		set name = ?
		WHERE id = ?`,
		[item.name, item.id]
	);
}

export function dbDeleteCase(item: CaseType) {
	// file category
	db!.run(
		`
		UPDATE cases 
		SET status = 0
		WHERE id = ?`,
		[item.id]
	);
	// case and file relationship
	db!.run(
		`
		UPDATE caselinkage 
		SET status = 0
		WHERE caseid = ?`,
		[item.id]
	);
}

// endregion

// region Case with File

export function dbInsertCaseFile(item: LinkCaseFileType) {
	db!.run(
		`
		INSERT INTO caselinkage 
		(fid, caseid, selfirst, selend, status, owner, date) 
		VALUES
		(?, ?, ?, ?, ?, 'oqda', ?)`,
		[item.fid, item.caseid, item.selfirst, item.selend, item.status, dateNow()]
	);
}

export function dbDeleteCaseFile(item: LinkCaseFileType) {
	db!.run(
		`
		UPDATE caselinkage 
		SET status = 0
		WHERE fid = ?
		AND caseid = ?
		AND status = 1`,
		[item.fid, item.caseid]
	);
}

export function dbUpdateMemo(
	table: string,
	id: string,
	item: { [propName: string]: string | number }
) {
	db!.run(
		`
		UPDATE ${table} 
		SET memo = ? 
		WHERE ${id} = ?`,
		[item.memo, item[id]]
	);
}

export function dbUpdateMemoProject(item: {
	[propName: string]: string | number;
}) {
	db!.run(
		`
		UPDATE project
		SET memo = ?`,
		[item.memo]
	);
}

export function dbReadMemoProject(): string {
	const res = db!.exec('SELECT * FROM project');
	const { data } = getTableData(res);
	return (data[0]?.memo as string) || '';
}

// endregion

// region Journal

export function dbInsertJournal(item: JournalType) {
	db!.run(
		`
		INSERT INTO journal 
		(name, journal, date, owner, status) 
		VALUES
		(?, ?, ?, ?, ?)`,
		[item.name, item.journal, item.date, item.owner, item.status]
	);
}

export function dbUpdateJournalName(item: JournalType, oldName: string) {
	db!.run(
		`
		UPDATE journal 
		set name = ?
		WHERE name = ?`,
		[item.name, oldName]
	);
}

export function dbUpdateJournalContents(item: JournalType) {
	db!.run(
		`
		UPDATE journal 
		set journal = ?
		WHERE name = ?`,
		[item.journal, item.name]
	);
}

export function dbDeleteJournal(item: JournalType) {
	db!.run(
		`
		UPDATE source 
		SET status = 0
		WHERE name = ?`,
		[item.name]
	);
}

// endregion
