import { Module } from 'vuex';
import { RootState } from '@/store';
import { CategoryType } from '@/types/sqlite';

interface TreeNode {
	id: number;
	name?: string;
	children?: TreeNode[];
	parentId: number;
}

const CategoryTreeModule: Module<{}, RootState> = {
	getters: {
		getCategoryTree: (state, getters, rootState) => {
			const validCategories = rootState.projectCurrent.categories.data.filter(
				(v) => v.status === 1
			);
			const categoriesFiltered: any = validCategories.reduce(
				(p, c) => ({
					...p,
					[c.catid]: { ...c, catChildren: [], codeChildren: [] }
				}),
				{}
			);
			const codesFiltered: any = rootState.projectCurrent.codes.data
				.filter((v) => v.status === 1)
				.reduce((p, c) => ({ ...p, [c.id]: c }), {});

			validCategories
				.filter((v: CategoryType) => v.parentid !== null)
				.forEach((c) => {
					if (!categoriesFiltered[c.parentid!]) {
						console.log('Missing parent', c);
						return;
					}
					categoriesFiltered[c.parentid!].catChildren.push(c.catid);
				});

			const markedFiles: any = {};
			rootState.projectCurrent.fileCodes.data
				.filter((v) => v.status === 1)
				.forEach((v) => {
					markedFiles[`c${v.cid}`] = markedFiles[`c${v.cid}`] || {
						marked: new Set(),
						files: new Set()
					};
					markedFiles[`c${v.cid}`].marked.add(v.id);
					markedFiles[`c${v.cid}`].files.add(v.fid);
				});

			rootState.projectCurrent.linksRaw
				.filter((v) => v.status === 1)
				.forEach((l) =>
					categoriesFiltered[l.catid].codeChildren.push({
						id: `c${l.cid}`,
						name: codesFiltered[l.cid].name,
						marked: markedFiles[`c${l.cid}`].marked.size,
						files: markedFiles[`c${l.cid}`].files.size
					})
				);

			function getCatInfo(childCatId: number) {
				const children = getChildren(childCatId); // eslint-disable-line
				let marked = 0;
				let files = 0;
				if (children.children) {
					children.children.forEach((v) => {
						markedFiles[childCatId] = markedFiles[childCatId] || {
							marked: new Set(),
							files: new Set()
						};
						// TODO: why do we need this check?
						if (markedFiles[v.id]) {
							markedFiles[v.id].marked.forEach((c: any) =>
								markedFiles[childCatId].marked.add(c)
							);
							markedFiles[v.id].files.forEach((c: any) =>
								markedFiles[childCatId].files.add(c)
							);
						}
					});
					marked = markedFiles[childCatId].marked.size;
					files = markedFiles[childCatId].files.size;
				}
				return {
					id: childCatId,
					name: categoriesFiltered[childCatId].name,
					marked,
					files,
					...children
				};
			}

			function getChildren(catid: number) {
				if (
					categoriesFiltered[catid].catChildren.length === 0 &&
					categoriesFiltered[catid].codeChildren.length === 0
				)
					return {};

				return {
					children: [
						...categoriesFiltered[catid].catChildren.map(getCatInfo),
						...categoriesFiltered[catid].codeChildren
					]
				};
			}

			return validCategories
				.filter((c) => c.parentid === null)
				.map((c) => c.catid)
				.map(getCatInfo);
			// .map((c) => ({ id: c.catid, name: c.name, ...getChildren(c.catid) }));
		}
	}
};

export default CategoryTreeModule;
