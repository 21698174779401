import Vue from 'vue';
import Vuetify from 'vuetify';
import * as icons from '@/components/icons';

Vue.use(Vuetify);

function removePrefix(iconNameWithPrefix: string) {
	return (
		iconNameWithPrefix.substr(4)[0].toLowerCase() + iconNameWithPrefix.substr(5)
	);
}

export default new Vuetify({
	theme: {
		options: {
			customProperties: true
		},
		themes: {
			light: {
				primary: '#009688',
				secondary: '#673ab7',
				accent: '#ffc107',
				error: '#f44336',
				warning: '#ff9800',
				info: '#00bcd4',
				success: '#4caf50'
			}
		}
	},
	icons: {
		iconfont: 'md',
		values: Object.entries(icons).reduce(
			(p, [key, component]) => ({ ...p, [removePrefix(key)]: { component } }),
			{}
		)
	}
});
