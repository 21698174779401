import { Module } from 'vuex';
import {
	dateNow,
	dateShort,
	dbDeleteCase,
	dbDeleteCaseFile,
	dbDeleteCategory,
	dbDeleteCategoryCode,
	dbDeleteCode,
	dbDeleteCodingByMark,
	dbDeleteFile,
	dbDeleteFileCategory,
	dbDeleteFileCategoryFile,
	dbDeleteJournal,
	dbInsertCase,
	dbInsertCaseFile,
	dbInsertCategory,
	dbInsertCategoryCode,
	dbInsertCode,
	dbInsertCoding,
	dbInsertFile,
	dbInsertFileCategory,
	dbInsertFileCategoryFile,
	dbInsertJournal,
	dbUpdateCase,
	dbUpdateCategory,
	dbUpdateCode,
	dbUpdateFile,
	dbUpdateFileCategory,
	dbUpdateFileContents,
	dbUpdateJournalContents,
	dbUpdateJournalName,
	dbUpdateMemo
} from '../shared/sqlite';
import colors from './colors';
import {
	CaseType,
	CategoryType,
	CodeType,
	FileCategoryType,
	FileType,
	JournalType,
	LinkCaseFileType,
	LinkCategoryCodeType,
	LinkFileCategoryFileType,
	MarkType
} from '@/types/sqlite';
import { logging } from '@/logging';
import { RootState } from '@/store';

const owner = 'oqda';

const log = logging('store:project-current:index');
export interface ProjectCurrentState {
	codes: {
		headers: [];
		data: CodeType[];
	};
	categories: {
		headers: [];
		data: CategoryType[];
	};
	sources: {
		headers: [];
		data: FileType[];
	};
	fileCategories: {
		headers: [];
		data: FileCategoryType[];
	};
	fileCodes: {
		headers: [];
		data: MarkType[];
	};
	cases: {
		headers: [];
		data: CaseType[];
	};
	linksRaw: LinkCategoryCodeType[];
	linksFile: LinkFileCategoryFileType[];
	linksCase: LinkCaseFileType[];
	filesIndex: { [prop: number]: number };
	codesIndex: { [prop: number]: number };
	journals: {
		headers: [];
		data: JournalType[];
	};
}

const projectCurrentModule: Module<ProjectCurrentState, RootState> = {
	state: () => ({
		codes: {
			headers: [],
			data: []
		},
		categories: {
			headers: [],
			data: []
		},
		sources: {
			headers: [],
			data: []
		},
		fileCategories: {
			headers: [],
			data: []
		},
		fileCodes: {
			headers: [],
			data: []
		},
		cases: {
			headers: [],
			data: []
		},
		linksRaw: [],
		linksFile: [],
		linksCase: [],
		filesIndex: {},
		codesIndex: {},
		journals: {
			headers: [],
			data: []
		}
	}),
	getters: {
		getFileNameById: (state) => (fid: number): string => {
			return state.sources.data[state.filesIndex[fid]].name;
		},
		getCodeNameById: (state) => (cid: number): string => {
			return state.codes.data[state.codesIndex[cid]].name;
		},
		getCodesByCategoryId: (state) => (catid: number): number[] => {
			return state.linksRaw
				.filter((v) => v.catid === catid && v.status === 1)
				.map((v) => v.cid);
		},
		getFilesByFileCategory: (state) => (catid: number): number[] => {
			return state.linksFile.filter((v) => v.catid === catid).map((v) => v.fid);
		},
		getFilesByCaseId: (state) => (caseid: number): number[] => {
			return state.linksCase
				.filter((v) => v.caseid === caseid)
				.map((v) => v.fid);
		},
		getMarksForFile: (state) => (fid: number): MarkType[] => {
			return state.fileCodes.data.filter(
				(v) => v.fid === fid && v.status === 1
			);
		},
		getMarksForCurrentFile: (state, getters): MarkType[] => {
			return state.fileCodes.data.filter(
				(v) => v.fid === getters.getSelectedFile.id && v.status === 1
			);
		},
		getMarkById: (state, getters) => (id: number) => {
			return state.fileCodes.data.find(
				(v) =>
					v.fid === getters.getSelectedFile.id && v.status === 1 && v.id === id
			);
		},
		getCodes: (state) => {
			return state.codes.data
				.filter((v) => v.status === 1)
				.map((v) => ({
					...v,
					color: colors[state.codesIndex[v.id] % colors.length]
				}));
		},
		getSelectedFile: (state, getters, rootState) => {
			const selectedFileId = parseInt(rootState.route.params.fileId, 10);
			if (!selectedFileId) return null;
			const selectedFile = state.sources.data.filter(
				(v) => v.id === selectedFileId
			);
			if (selectedFile.length !== 1) return null;
			return selectedFile[0];
		},
		getSelectedJournal: (state, getters, rootState) => {
			const selectedJournalName = rootState.route.params.journalId;
			if (!selectedJournalName) return null;
			const selectedJournal = state.journals.data.filter(
				(v) => v.name === selectedJournalName
			);
			if (selectedJournal.length !== 1) return null;
			return selectedJournal[0];
		}
	},
	mutations: {
		currentFile(state, currentFile) {
			state.categories = currentFile.categories;
			state.sources = currentFile.sources;
			state.fileCategories = currentFile.fileCategories;
			state.fileCodes = currentFile.fileCodes;
			state.linksRaw = currentFile.linksRaw;
			state.codesIndex = currentFile.codesIndex;
			state.filesIndex = currentFile.filesIndex;
			state.linksFile = currentFile.linksFile;
			state.codes = currentFile.codes;
			state.cases = currentFile.cases;
			state.linksCase = currentFile.linksCase;
			state.journals = currentFile.journals;
		},
		// region Mark
		addFileCode(state, newFileCode: MarkType) {
			state.fileCodes.data = [...state.fileCodes.data, newFileCode];
		},
		removeFileCodeByMark(state, fileCode: MarkType) {
			state.fileCodes.data = state.fileCodes.data.map((v) =>
				v.cid === fileCode.cid &&
				v.selfirst === fileCode.selfirst &&
				v.selend === fileCode.selend
					? { ...v, status: 0 }
					: v
			);
		},
		updateFileCodeByMark(state, [previousMark, newMark]: MarkType[]) {
			state.fileCodes.data = state.fileCodes.data.map((v) =>
				v.cid === previousMark.cid &&
				v.selfirst === previousMark.selfirst &&
				v.selend === previousMark.selend
					? {
							...v,
							selfirst: newMark.selfirst,
							selend: newMark.selend,
							seltext: newMark.seltext
					  }
					: v
			);
		},
		// endregion
		// region Code
		addFreeCode(state, newCode) {
			state.codesIndex = {
				...state.codesIndex,
				[newCode.id]: state.codes.data.length
			};
			state.codes.data = [...state.codes.data, newCode];
		},
		updateFreeCode(state, code) {
			state.codes.data = state.codes.data.map((v) =>
				v.id === code.id ? { ...v, name: code.name } : v
			);
		},
		removeFreeCode(state, code) {
			// delete code
			state.codes.data = state.codes.data.map((v) =>
				v.id === code.id ? { ...v, status: 0 } : v
			);

			// delete markings with code
			state.fileCodes.data = state.fileCodes.data.map((v) =>
				v.cid === code.id ? { ...v, status: 0 } : v
			);

			// delete code category relationships
			state.linksRaw = state.linksRaw.map((v) =>
				v.cid === code.id ? { ...v, status: 0 } : v
			);
		},
		// endregion
		// region Category
		addCategory(state, item) {
			state.categories.data = [...state.categories.data, item];
		},
		updateCategory(state, item) {
			state.categories.data = state.categories.data.map((v) =>
				v.catid === item.catid ? { ...v, ...item } : v
			);
		},
		removeCategory(state, item) {
			// delete category
			state.categories.data = state.categories.data.map((v) =>
				v.catid === item.catid ? { ...v, status: 0 } : v
			);

			// delete code category relationships
			state.linksRaw = state.linksRaw.map((v) =>
				v.catid === item.catid ? { ...v, status: 0 } : v
			);
		},
		// Category Code relationship
		addCategoryCode(state, item) {
			state.linksRaw = [...state.linksRaw, item];
		},
		removeCategoryCode(state, item) {
			state.linksRaw = state.linksRaw.map((v) =>
				v.catid === item.catid && v.cid === item.cid && v.status === 1
					? { ...v, status: 0 }
					: v
			);
		},
		// endregion
		// region File
		addFile(state, item) {
			state.sources.data = [...state.sources.data, item];
		},
		updateFile(state, item) {
			state.sources.data = state.sources.data.map((v) =>
				v.id === item.id ? { ...v, name: item.name } : v
			);
		},
		updateFileContents(state, item) {
			state.sources.data = state.sources.data.map((v) =>
				v.id === item.id ? { ...v, file: item.file } : v
			);
		},
		removeFile(state, item) {
			// delete file
			state.sources.data = state.sources.data.map((v) =>
				v.id === item.id ? { ...v, status: 0 } : v
			);

			// markings
			state.fileCodes.data = state.fileCodes.data.map((v) =>
				v.fid === item.id ? { ...v, status: 0 } : v
			);

			// linksFile
			state.linksFile = state.linksFile.map((v) =>
				v.fid === item.id ? { ...v, status: 0 } : v
			);

			// file cat
		},
		// endregion
		// region File Category
		addFileCategory(state, item) {
			state.fileCategories.data = [...state.fileCategories.data, item];
		},
		updateFileCategory(state, item) {
			state.fileCategories.data = state.fileCategories.data.map((v) =>
				v.catid === item.catid ? { ...v, name: item.name } : v
			);
		},
		removeFileCategory(state, item) {
			// delete category
			state.fileCategories.data = state.fileCategories.data.map((v) =>
				v.catid === item.catid ? { ...v, status: 0 } : v
			);

			// delete file category with file relationships
			state.linksFile = state.linksFile.map((v) =>
				v.catid === item.catid ? { ...v, status: 0 } : v
			);
		},
		// File Category with File relationship
		addFileCategoryFile(state, item) {
			state.linksFile = [...state.linksFile, item];
		},
		removeFileCategoryFile(state, item) {
			state.linksFile = state.linksFile.map((v) =>
				v.catid === item.catid && v.fid === item.fid && v.status === 1
					? { ...v, status: 0 }
					: v
			);
		},
		// endregion
		// region Case
		addCase(state, item: CaseType) {
			state.cases.data = [...state.cases.data, item];
		},
		updateCase(state, item: CaseType) {
			state.cases.data = state.cases.data.map((v) =>
				v.id === item.id ? { ...v, name: item.name } : v
			);
		},
		removeCase(state, item: CaseType) {
			// delete case
			state.cases.data = state.cases.data.map((v) =>
				v.id === item.id ? { ...v, status: 0 } : v
			);

			// delete files from case
			state.linksCase = state.linksCase.map((v) =>
				v.caseid === item.id ? { ...v, status: 0 } : v
			);
		},
		// endregion
		// region Link File in Case
		addCaseFile(state, item) {
			state.linksCase = [...state.linksCase, item];
		},
		removeCaseFile(state, item) {
			state.linksCase = state.linksCase.map((v) =>
				v.caseid === item.caseid && v.fid === item.fid && v.status === 1
					? { ...v, status: 0 }
					: v
			);
		},
		// endregion
		// region Memo
		// TODO: on memo save make the button loading and close window once is save
		updateMemo(
			state,
			{
				item,
				table,
				id
			}: {
				item: { memo: string; [_: string]: string };
				table: string;
				id: string;
			}
		) {
			(state as any)[table].data = (state as any)[
				table
			].data.map((v: { memo: string; [key: string]: string }) =>
				v[id] === item[id] ? { ...v, memo: item.memo } : v
			);
		},
		// endregion
		// region File
		addJournal(state, item) {
			state.journals.data = [...state.journals.data, item];
		},
		updateJournal(state, { newItem, oldItem }) {
			state.journals.data = state.journals.data.map((v) =>
				v.name === oldItem.name ? { ...v, name: newItem.name } : v
			);
		},
		updateJournalContents(state, item) {
			state.journals.data = state.journals.data.map((v) =>
				v.name === item.name ? { ...v, journal: item.journal } : v
			);
		},
		removeJournal(state, item) {
			state.journals.data = state.journals.data.map((v) =>
				v.name === item.name ? { ...v, status: 0 } : v
			);
		}
		// endregion
	},
	actions: {
		// region Code
		addCode({ commit, dispatch, state }, newCodeName) {
			const id = state.codes.data.reduce(
				(p, c) => (p <= c.id ? c.id + 1 : p),
				1
			);
			const newCode = { name: newCodeName, status: 1, id };
			dbInsertCode(newCode);
			commit('addFreeCode', newCode);
			dispatch('createMark', newCode);
			dispatch('updateOpenProjectData');
		},
		updateCode({ commit, dispatch }, updatedCode) {
			dbUpdateCode(updatedCode);
			commit('updateFreeCode', updatedCode);
			dispatch('updateEditor');
			dispatch('updateOpenProjectData');
		},
		deleteCode({ commit, dispatch }, code) {
			dbDeleteCode(code);
			commit('removeFreeCode', code);
			dispatch('updateEditor');
			dispatch('updateOpenProjectData');
		},
		updateMemoCode({ commit, dispatch }, item) {
			dbUpdateMemo('freecode', 'id', item);
			commit('updateMemo', { item, table: 'codes', id: 'id' });
			dispatch('updateOpenProjectData');
		},
		// endregion
		// region Mark
		addMark({ commit, dispatch }, newFileCode) {
			const id = dbInsertCoding(newFileCode);
			const mark = { ...newFileCode, id };
			commit('addFileCode', mark);
			dispatch('updateEditor');
			dispatch('updateOpenProjectData');
		},
		deleteMarkById({ commit, dispatch, rootGetters }, id) {
			const mark = rootGetters.getMarkById(id);
			dbDeleteCodingByMark(mark);
			commit('removeFileCodeByMark', mark);
			dispatch('updateEditor');
			dispatch('updateOpenProjectData');
		},
		// endregion
		// region Category
		addCategory({ commit, dispatch, state }, item) {
			const catid = state.categories.data.reduce(
				(p, c) => (p <= c.catid ? c.catid + 1 : p),
				1
			);
			const newCategory = { ...item, status: 1, catid };
			dbInsertCategory(newCategory);
			commit('addCategory', newCategory);
			dispatch('updateOpenProjectData');
		},
		updateCategory({ commit, dispatch }, updatedItem) {
			dbUpdateCategory(updatedItem);
			commit('updateCategory', updatedItem);
			dispatch('updateOpenProjectData');
		},
		deleteCategory({ commit, dispatch }, item) {
			dbDeleteCategory(item);
			commit('removeCategory', item);
			dispatch('updateOpenProjectData');
		},
		updateMemoCategory({ commit, dispatch }, item) {
			dbUpdateMemo('codecat', 'catid', item);
			commit('updateMemo', { item, table: 'categories', id: 'catid' });
			dispatch('updateOpenProjectData');
		},
		// endregion
		// region Category Code relationship
		addCategoryCode(
			{ commit, dispatch },
			item: { cid: number; catid: number }
		) {
			const newCategoryCode = { ...item, status: 1 };
			dbInsertCategoryCode(newCategoryCode);
			commit('addCategoryCode', newCategoryCode);
			dispatch('updateOpenProjectData');
		},
		deleteCategoryCode(
			{ commit, dispatch },
			item: { cid: number; catid: number }
		) {
			dbDeleteCategoryCode(item);
			commit('removeCategoryCode', item);
			dispatch('updateOpenProjectData');
		},
		// endregion
		// region File
		addFile({ commit, dispatch, state }, partialItem) {
			const id = state.sources.data.reduce(
				(p, c) => (p <= c.id ? c.id + 1 : p),
				1
			);
			const item = { id, status: 1, ...partialItem };
			dbInsertFile(item);
			commit('addFile', item);
			dispatch('updateOpenProjectData');
		},
		updateFile({ commit, dispatch }, item) {
			dbUpdateFile(item);
			commit('updateFile', item);
			dispatch('updateOpenProjectData');
		},
		updateCurrentFileContents({ commit, dispatch, getters }, file) {
			const currentFileWithNewContent = {
				...getters.getSelectedFile,
				file
			};
			log('updateFileContents', { currentFileWithNewContent });
			dbUpdateFileContents(currentFileWithNewContent);
			commit('updateFileContents', currentFileWithNewContent);
			dispatch('updateOpenProjectData');
		},
		deleteFile({ commit, dispatch }, item) {
			dbDeleteFile(item);
			commit('removeFile', item);
			dispatch('updateOpenProjectData');
		},
		updateMemoFile({ commit, dispatch }, item) {
			dbUpdateMemo('source', 'id', item);
			commit('updateMemo', { item, table: 'sources', id: 'id' });
			dispatch('updateOpenProjectData');
		},
		// endregion
		// region File Category
		addFileCategory({ commit, dispatch, state }, newItemName: string) {
			const catid = state.fileCategories.data.reduce(
				(p, c) => (p <= c.catid ? c.catid + 1 : p),
				1
			);
			const newItem = { name: newItemName, status: 1, catid };
			dbInsertFileCategory(newItem);
			commit('addFileCategory', newItem);
			dispatch('updateOpenProjectData');
		},
		updateFileCategory({ commit, dispatch }, updatedItem) {
			dbUpdateFileCategory(updatedItem);
			commit('updateFileCategory', updatedItem);
			dispatch('updateOpenProjectData');
		},
		deleteFileCategory({ commit, dispatch }, item) {
			dbDeleteFileCategory(item);
			commit('removeFileCategory', item);
			dispatch('updateOpenProjectData');
		},
		updateMemoFileCategory({ commit, dispatch }, item) {
			dbUpdateMemo('filecat', 'catid', item);
			commit('updateMemo', { item, table: 'fileCategories', id: 'catid' });
			dispatch('updateOpenProjectData');
		},
		// endregion
		// region File Category with File relationship
		addFileCategoryFile(
			{ commit, dispatch },
			item: { fid: number; catid: number }
		) {
			const newItem = { ...item, status: 1 };
			dbInsertFileCategoryFile(newItem);
			commit('addFileCategoryFile', newItem);
			dispatch('updateOpenProjectData');
		},
		deleteFileCategoryFile(
			{ commit, dispatch },
			item: { fid: number; catid: number }
		) {
			dbDeleteFileCategoryFile(item);
			commit('removeFileCategoryFile', item);
			dispatch('updateOpenProjectData');
		},
		// endregion
		// region Case
		addCase({ commit, dispatch, state }, newItemName: string) {
			const id = state.cases.data.reduce(
				(p, c) => (p <= c.id ? c.id + 1 : p),
				1
			);
			const newItem = { name: newItemName, status: 1, id };
			dbInsertCase(newItem);
			commit('addCase', newItem);
			dispatch('updateOpenProjectData');
		},
		updateCase({ commit, dispatch }, updatedItem) {
			dbUpdateCase(updatedItem);
			commit('updateCase', updatedItem);
			dispatch('updateOpenProjectData');
		},
		deleteCase({ commit, dispatch }, item) {
			dbDeleteCase(item);
			commit('removeCase', item);
			dispatch('updateOpenProjectData');
		},
		updateMemoCase({ commit, dispatch }, item) {
			dbUpdateMemo('cases', 'id', item);
			commit('updateMemo', { item, table: 'cases', id: 'id' });
			dispatch('updateOpenProjectData');
		},
		// endregion
		// region Case with File relationship
		addCaseFile({ commit, dispatch }, item: LinkCaseFileType) {
			const newItem = { ...item, status: 1 };
			dbInsertCaseFile(newItem);
			commit('addCaseFile', newItem);
			dispatch('updateOpenProjectData');
		},
		deleteCaseFile({ commit, dispatch }, item: LinkCaseFileType) {
			dbDeleteCaseFile(item);
			commit('removeCaseFile', item);
			dispatch('updateOpenProjectData');
		},
		// endregion
		// region Journal
		addJournal({ commit, dispatch }, partialItem) {
			const date = new Date();
			const item = {
				...partialItem,
				status: 1,
				date: dateNow(date),
				owner
			};
			dbInsertJournal(item);
			commit('addJournal', item);
			dispatch('updateOpenProjectData');
			return item;
		},
		updateJournalName({ commit, dispatch }, payload) {
			dbUpdateJournalName(payload.newItem, payload.oldItem.name);
			commit('updateJournal', payload);
			dispatch('updateOpenProjectData');
		},
		updateCurrentJournalContents({ commit, dispatch, getters }, journal) {
			const currentJournalWithNewContent = {
				...getters.getSelectedJournal,
				journal
			};
			log('updateJournalContents', { currentJournalWithNewContent });
			dbUpdateJournalContents(currentJournalWithNewContent);
			commit('updateJournalContents', currentJournalWithNewContent);
			dispatch('updateOpenProjectData');
		},
		deleteJournal({ commit, dispatch }, item) {
			dbDeleteJournal(item);
			commit('removeJournal', item);
			dispatch('updateOpenProjectData');
		}
		// endregion
	}
};

export default projectCurrentModule;
