import { ActionContext, Module } from 'vuex';
import {
	deleteFile,
	getFile,
	getFiles,
	saveFile,
	updateFile
} from './gapi-file';
import {
	convertDataToRQDA,
	dbClean,
	dbExport,
	dbInsertProject,
	dbReadMemoProject,
	dbUpdateMemoProject,
	loadDataFromFile
} from '../shared/sqlite';
import { convertGapiFileToBlob, downloadFile } from './manage-file';
import { RootState } from '@/store';
import { debounce } from 'lodash';
import { logging } from '@/logging';

const log = logging('store:project-list:index');

export interface FileItem {
	id: string;
	name: string;
	isOpen: boolean;
	isLocal: boolean;
	memo?: string;
}

interface FileGDrive {
	id: string;
	name: string;
}

export interface ProjectListState {
	isLoading: boolean;
	isSaving: boolean;
	projects: {
		headers: {};
		data: FileItem[];
	};
}

let nextId = 0;

const projectListModule: Module<ProjectListState, RootState> = {
	state: () => ({
		isLoading: true,
		isSaving: false,
		projects: {
			headers: [
				{ text: 'Project file', value: 'name' },
				{ text: 'Status', value: 'status', sortable: false },
				{ text: 'Actions', value: 'actions', sortable: false }
			],
			data: []
		}
	}),
	getters: {
		projectIsLoaded: (state, getters) => {
			const openProject = getters.fileProjectOpen;
			return openProject ? openProject.name : null;
		},
		fileProjectOpen: (state) => {
			const openProject = state.projects.data.find((v) => v.isOpen);
			return openProject ? openProject : null;
		},
		projectIsSaving: (state) => state.isSaving,
		isRqdaFile: () => (filename: string) => {
			return filename.endsWith('.rqda');
		},
		isOqdaFile: () => (filename: string) => {
			return filename.endsWith('.oqda');
		},
		convertToOqdaFilename: (_, getters) => (filename: string) => {
			if (getters.isRqdaFile(filename))
				return filename.substr(0, filename.length - 5) + '.oqda';
			if (getters.isOqdaFile(filename)) return filename;
			throw new Error('Wrong file type');
		}
	},
	mutations: {
		openProject(state, file: FileItem) {
			state.projects.data = [
				...state.projects.data.map((v) =>
					v.id === file.id
						? { ...file, isOpen: true }
						: v.isOpen
						? { ...v, isOpen: false }
						: v
				)
			];
		},
		closeProject(state) {
			state.projects.data = [
				...state.projects.data.map((v) =>
					v.isOpen ? { ...v, isOpen: false } : v
				)
			];
		},
		updateOpenProjectWithGDriveId(state, fileId: string) {
			const file = state.projects.data.filter((v) => v.isOpen)[0];
			if (!file) return;
			state.projects.data = [
				...state.projects.data.map((v) =>
					v.id === file.id ? { ...file, id: fileId, isLocal: false } : v
				)
			];
		},
		removeAllGDriveFiles(state) {
			state.projects.data = [...state.projects.data.filter((v) => v.isLocal)];
		},
		updateGDriveFiles(state, files) {
			const fileNamesByIds = files.reduce(
				(p: {}, c: FileGDrive) => ({
					...p,
					[c.id]: c.name
				}),
				{}
			);
			const idsAlreadyInTheList: { [propName: string]: boolean } = {};
			state.projects.data = [
				...state.projects.data
					.filter((v) => v.isLocal || (!v.isLocal && fileNamesByIds[v.id]))
					.map((v: FileItem) => {
						idsAlreadyInTheList[v.id] = true;
						return fileNamesByIds[v.id]
							? { ...v, name: fileNamesByIds[v.id] }
							: v;
					}),
				...files
					.filter((v: FileGDrive) => !idsAlreadyInTheList[v.id])
					.map((v: FileGDrive) => ({ ...v, isLocal: false, isOpen: false }))
			];

			state.isLoading = false;
		},
		addLocalFile(state, file: FileItem) {
			state.projects.data = [file, ...state.projects.data];
		},
		removeLocalFile(state, file: FileItem) {
			state.projects.data = state.projects.data.filter((v) => v.id !== file.id);
		},
		saving(state, status) {
			state.isSaving = status;
		},
		isLoading(state, isLoading) {
			state.isLoading = isLoading;
		},
		updateMemoProject(state, item) {
			state.projects.data = [
				...state.projects.data.map((v) =>
					v.id === item.id ? { ...v, memo: item.memo } : v
				)
			];
		}
	},
	actions: {
		start({ state }) {
			window.onbeforeunload = () => {
				if (state.isSaving)
					return (
						'It looks like you have been editing something' +
						' - if you leave before saving, then your changes will be lost.'
					);
				else return undefined;
			};
		},
		isAuthenticated({ commit, dispatch }, isAuthenticated) {
			if (isAuthenticated) {
				dispatch('saveOpen');
				dispatch('loadFiles');
			} else {
				commit('removeAllGDriveFiles');
				commit('isLoading', false);
			}
		},
		async uploadFileProject({ commit, dispatch, getters }, file: File) {
			if (!file) return;
			if (!getters.isOqdaFile(file.name) && !getters.isRqdaFile(file.name)) {
				alert('Wrong file type');
				return;
			}
			await dispatch('closeProject');
			const currentFile = await loadDataFromFile(file);
			const memo = dbReadMemoProject();
			const name = getters.convertToOqdaFilename(file.name);
			const newFile: FileItem = {
				name,
				id: `LOCAL_ID_${nextId++}`,
				isOpen: false,
				isLocal: true,
				memo
			};
			commit('addLocalFile', newFile);
			commit('openProject', newFile);
			commit('currentFile', currentFile);
			dispatch('saveOpen');
		},
		async closeProject({ commit, getters }) {
			const fileOpen = getters.fileProjectOpen;
			if (!fileOpen) return;
			if (fileOpen.isLocal) {
				commit('removeLocalFile', fileOpen);
			}
			commit('closeProject');
		},
		async newProject({ commit, dispatch }, projectName) {
			const emptyFile = await fetch('/empty.rqda').then((r) => r.blob());
			await dispatch(
				'uploadFileProject',
				new File([emptyFile], `${projectName}.rqda`)
			);
			dbInsertProject();
			dispatch('updateOpenProjectData');
		},
		async saveOpen({ commit, getters, dispatch, rootState }) {
			const fileOpen = getters.fileProjectOpen;
			if (!fileOpen) return;
			if (fileOpen.isLocal && !rootState.auth.isAuthenticated) return;
			const fileData = dbExport();
			if (!fileData) return;
			const fileId = await saveFile(fileData, getters.projectIsLoaded);
			commit('updateOpenProjectWithGDriveId', fileId);
			dispatch('loadFiles');
		},
		async loadFiles({ commit }) {
			const files = await getFiles();
			commit('updateGDriveFiles', files);
		},
		async deleteProject({ dispatch }, file: FileItem) {
			await deleteFile(file.id);
			dispatch('loadFiles');
		},
		async downloadProjectOqda({ dispatch }, file: FileItem) {
			dispatch('downloadProjectFile', { file, convertToRQDA: false });
		},
		async downloadProjectRqda({ dispatch, getters }, file: FileItem) {
			if (getters.isRqdaFile(file.name))
				dispatch('downloadProjectFile', { file, convertToRQDA: false });
			if (getters.isOqdaFile(file.name))
				dispatch('downloadProjectFile', { file, convertToRQDA: true });
		},
		async downloadProjectFile(
			{ getters },
			{ file, convertToRQDA }: { file: FileItem; convertToRQDA: boolean }
		) {
			let fileName: string;
			let fileData: Blob | null;
			if (file.isLocal) {
				if (!getters.projectIsLoaded) return;
				fileName = getters.projectIsLoaded;
				fileData = dbExport();
			} else {
				const gapiFileData = await getFile(file.id);
				fileName = file.name;
				fileData = convertGapiFileToBlob(gapiFileData);
			}
			if (convertToRQDA) {
				fileData = await convertDataToRQDA(fileData!);
				fileName = fileName.substr(0, fileName.length - 5) + '.rqda';
			}
			if (!fileData) return;
			downloadFile(fileData, fileName);
		},
		async openProject({ commit }, file: FileItem) {
			const gapiFileData = await getFile(file.id);
			const fileBlob = convertGapiFileToBlob(gapiFileData);
			if (!fileBlob) return;
			const fileFile = new File([fileBlob], file.name);
			const currentFile = await loadDataFromFile(fileFile);
			file.memo = dbReadMemoProject();

			commit('openProject', file);
			commit('currentFile', currentFile);
		},
		updateOpenProjectData({ commit, dispatch }) {
			log('starting save...');
			commit('saving', true);
			dispatch('debouncedUpdateOpenProjectData');
		},
		debouncedUpdateOpenProjectData: debounce(
			async ({
				commit,
				getters
			}: ActionContext<ProjectListState, RootState>) => {
				const fileOpen = getters.fileProjectOpen;
				if (!fileOpen || fileOpen.isLocal) {
					commit('saving', false);
					return;
				}
				const fileData = dbExport();
				if (!fileData) {
					commit('saving', false);
					return;
				}

				await updateFile(fileData, fileOpen);
				commit('saving', false);
				log('finished save!');
			},
			1000,
			{ maxWait: 10000 }
		),
		async cleanProject({ commit, dispatch }, file: FileItem) {
			if (!file.isOpen) return;
			dbClean();
			const fileBlob = dbExport();
			const fileFile = new File([fileBlob!], file.name);
			const currentFile = await loadDataFromFile(fileFile);
			commit('currentFile', currentFile);
			dispatch('updateOpenProjectData');
		},
		updateMemoProject({ commit, dispatch }, item) {
			dbUpdateMemoProject(item);
			commit('updateMemoProject', item);
			dispatch('updateOpenProjectData');
		}
	}
};

export default projectListModule;
