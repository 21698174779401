import Vue from 'vue';
import Vuex from 'vuex';
import auth, { AuthState } from './auth';
import graph, { GraphState } from './graph';
import marking, { MarkingState } from './marking';
import projectCurrent, { ProjectCurrentState } from './project-current';
import projectList, { ProjectListState } from './project-list';
import ui, { UiState } from './ui';
import textEditor, { TextEditorState } from './text-editor';
import { RouteState } from '@/router/sync';
import categoryTree from './category-tree';

Vue.use(Vuex);

export interface RootState {
	auth: AuthState;
	projectCurrent: ProjectCurrentState;
	projectList: ProjectListState;
	ui: UiState;
	graph: GraphState;
	marking: MarkingState;
	textEditor: TextEditorState;
	route: RouteState;
}

export default new Vuex.Store<RootState>({
	modules: {
		auth,
		projectCurrent,
		projectList,
		ui,
		graph,
		marking,
		textEditor,
		categoryTree
	}
});
