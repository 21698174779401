




































import Vue from 'vue';

export default Vue.extend({
	name: 'VMemo',
	props: ['dialog', 'initialItem'],
	data() {
		return { item: this.initialItem };
	},
	watch: {
		dialog: function() {
			this.item = this.initialItem;
		}
	}
});
