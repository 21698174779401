export default function getCategoriesLinks(
	links: {
		cid: number;
		catid: number;
	}[]
): [number, number][] {
	const codesToCategories: { [prop: number]: Set<number> } = {};
	links.forEach((v: { cid: number; catid: number }) => {
		if (!codesToCategories[v.cid]) codesToCategories[v.cid] = new Set();
		codesToCategories[v.cid].add(v.catid);
	});
	const categoriesToCategories: {
		[prop: number]: Set<number>;
	} = {};
	Object.values(codesToCategories).forEach((v) => {
		const categories = Array.from(v);
		if (categories.length > 1) {
			for (let i = 0; i < categories.length - 1; i++) {
				for (let j = i + 1; j < categories.length; j++) {
					const [category1, category2] = [categories[i], categories[j]];
					if (
						categoriesToCategories[category2] &&
						categoriesToCategories[category2].has(category1)
					)
						continue;
					if (!categoriesToCategories[category1])
						categoriesToCategories[category1] = new Set();
					categoriesToCategories[category1].add(category2);
				}
			}
		}
	});

	const linksBetweenCategories: [number, number][] = [];
	Object.entries(categoriesToCategories).forEach(([category1, categories]) => {
		categories.forEach((category2) => {
			linksBetweenCategories.push([Number(category1), category2]);
		});
	});
	return linksBetweenCategories;
}
