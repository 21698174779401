//https://coolors.co/009688-673ab7-bc4da0-d2b13a-d66f4d-326fbf-536071-de4343-a36b3b-5eaf3b
const colors = [
	'#009688',
	'#673ab7',
	'#bc4da0',
	'#d2b13a',
	'#d66f4d',
	'#326fbf',
	'#536071',
	'#de4343',
	'#a36b3b',
	'#5eaf3b'
];

export default colors;
