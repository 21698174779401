import { saveSvgAsPng } from 'save-svg-as-png';
import { Module } from 'vuex';
import { RootState } from '..';
import getCategoriesLinks from './get-categories-links';
import { CategoryType } from '@/types/sqlite';

interface ItemType {
	text: string;
	value: {
		id: number | string;
		type: 'level' | 'file' | 'fileCategory' | 'case';
	};
}

export interface GraphState {
	filterSelection: ItemType[];
}

interface LinkType {
	source: number;
	target: number;
	value: number;
	type: string;
}

const mapToGraphNode = (nodeType: string) => (v: { name: string }) => ({
	name: v.name,
	group: 1,
	class: nodeType
});

function findCategoryLevel(
	cat: CategoryType,
	categoriesFiltered: CategoryType[],
	categoriesIndex: { [_: number]: number }
): number {
	if (cat.parentid === null) return 0;
	return (
		findCategoryLevel(
			categoriesFiltered[categoriesIndex[cat.parentid]],
			categoriesFiltered,
			categoriesIndex
		) + 1
	);
}

function getCategoriesParents(
	catid: number,
	categoriesParent: Set<number>,
	categories: CategoryType[],
	categoriesIndex: { [_: number]: number }
) {
	const c = categories[categoriesIndex[catid]];
	if (c.parentid === null || c.parentid === undefined) return;
	categoriesParent.add(c.parentid);
	getCategoriesParents(
		c.parentid,
		categoriesParent,
		categories,
		categoriesIndex
	);
}

const GraphModule: Module<GraphState, RootState> = {
	state: () => ({
		filterSelection: []
	}),
	getters: {
		graphDataFiltered: (state, getters, rootState) => {
			if (state.filterSelection.length === 0) {
				const categoriesFiltered = rootState.projectCurrent.categories.data.filter(
					(v) => v.status === 1
				);
				const codesFiltered = rootState.projectCurrent.codes.data.filter(
					(v) => v.status === 1
				);
				const codesIndex: { [prop: number]: number } = codesFiltered.reduce(
					(p, c: { id: number }, i) => ({ ...p, [c.id]: i }),
					{}
				);
				const categoriesIndex: {
					[prop: number]: number;
				} = categoriesFiltered.reduce(
					(p, c: { catid: number }, i) => ({
						...p,
						[c.catid]: i
					}),
					{}
				);

				const catLinks: any[] = [];
				const nodes = [
					...categoriesFiltered.map((c) => {
						const level = findCategoryLevel(
							c,
							categoriesFiltered,
							categoriesIndex
						);
						if (c.parentid)
							catLinks.push({
								source: categoriesIndex[c.catid],
								target: categoriesIndex[c.parentid],
								value: 1,
								type: 'depends'
							});
						return mapToGraphNode(`category${level}`)(c);
					}),
					...codesFiltered.map(mapToGraphNode('code'))
				];
				const links = [
					...catLinks,
					...rootState.projectCurrent.linksRaw
						.filter((v) => v.status === 1)
						.map((v) => ({
							source: codesIndex[v.cid] + categoriesFiltered.length,
							target: categoriesIndex[v.catid],
							value: 1,
							type: 'depends'
						}))
				];
				return { nodes, links };
			}

			const selectCategoriesOnly = state.filterSelection
				.filter((v) => v.value.type === 'level')
				.map((v) => v.value.id);
			const isCategoriesOnly =
				selectCategoriesOnly.length && selectCategoriesOnly[0] === 'categories';
			const hasOnlyOneFilter = state.filterSelection.length === 1;

			const selectedFiles = state.filterSelection
				.filter((v) => v.value.type === 'file')
				.map((v) => v.value.id);

			const selectedFileCategories = state.filterSelection
				.filter((v) => v.value.type === 'fileCategory')
				.map((v) => v.value.id);
			if (selectedFileCategories.length)
				rootState.projectCurrent.linksFile
					.filter((v) => selectedFileCategories.includes(v.catid))
					.forEach((v) => {
						if (selectedFiles.includes(v.fid)) return;
						selectedFiles.push(v.fid);
					});

			const selectedCases = state.filterSelection
				.filter((v) => v.value.type === 'case')
				.map((v) => v.value.id);
			if (selectedCases.length)
				rootState.projectCurrent.linksCase
					.filter((v) => selectedCases.includes(v.caseid))
					.forEach((v) => {
						if (selectedFiles.includes(v.fid)) return;
						selectedFiles.push(v.fid);
					});

			const isCategoriesOnlyAndOnlyFilter =
				selectedFiles.length === 0 && isCategoriesOnly && hasOnlyOneFilter;

			const codesInsideSelection = new Set(
				rootState.projectCurrent.fileCodes.data
					.filter(
						(v) =>
							v.status === 1 &&
							(isCategoriesOnlyAndOnlyFilter || selectedFiles.includes(v.fid))
					)
					.map((v) => v.cid)
			);

			const codesFiltered = rootState.projectCurrent.codes.data.filter(
				(v) => v.status === 1 && codesInsideSelection.has(v.id)
			);
			const linksFiltered = rootState.projectCurrent.linksRaw.filter(
				(v) => v.status === 1 && codesInsideSelection.has(v.cid)
			);
			const categoriesInsideSelection = new Set(
				linksFiltered.map((v: { catid: number }) => v.catid)
			);
			const categoriesParent = new Set<number>();
			const fullCategoriesIndex: {
				[prop: number]: number;
			} = rootState.projectCurrent.categories.data.reduce(
				(p, c: { catid: number }, i) => ({
					...p,
					[c.catid]: i
				}),
				{}
			);
			categoriesInsideSelection.forEach((catid) => {
				getCategoriesParents(
					catid,
					categoriesParent,
					rootState.projectCurrent.categories.data,
					fullCategoriesIndex
				);
			});
			const categoriesFiltered = rootState.projectCurrent.categories.data.filter(
				(v) =>
					v.status === 1 &&
					(isCategoriesOnlyAndOnlyFilter ||
						categoriesInsideSelection.has(v.catid) ||
						categoriesParent.has(v.catid))
			);
			const codesIndex: { [prop: number]: number } = codesFiltered.reduce(
				(p, c: { id: number }, i) => ({ ...p, [c.id]: i }),
				{}
			);
			const categoriesIndex: {
				[prop: number]: number;
			} = categoriesFiltered.reduce(
				(p, c: { catid: number }, i) => ({
					...p,
					[c.catid]: i
				}),
				{}
			);

			let nodes;
			let links: LinkType[];

			const categoryParentLinks: LinkType[] = [];
			if (isCategoriesOnly) {
				nodes = [
					...categoriesFiltered.map((c) => {
						const level = findCategoryLevel(
							c,
							categoriesFiltered,
							categoriesIndex
						);
						if (c.parentid)
							categoryParentLinks.push({
								source: categoriesIndex[c.catid],
								target: categoriesIndex[c.parentid],
								value: 1,
								type: 'depends'
							});
						return mapToGraphNode(`category${level}`)(c);
					})
				];
				links = [
					...categoryParentLinks,
					...getCategoriesLinks(linksFiltered).map((v: number[]) => ({
						source: categoriesIndex[v[0]],
						target: categoriesIndex[v[1]],
						value: 1,
						type: 'depends'
					}))
				];
			} else {
				nodes = [
					...categoriesFiltered.map((c) => {
						const level = findCategoryLevel(
							c,
							categoriesFiltered,
							categoriesIndex
						);
						if (c.parentid) {
							categoryParentLinks.push({
								source: categoriesIndex[c.catid],
								target: categoriesIndex[c.parentid],
								value: 1,
								type: 'depends'
							});
						}
						return mapToGraphNode(`category${level}`)(c);
					}),
					...codesFiltered.map(mapToGraphNode('code'))
				];
				links = [
					...categoryParentLinks,
					...linksFiltered.map((v: { cid: number; catid: number }) => ({
						source: codesIndex[v.cid] + categoriesFiltered.length,
						target: categoriesIndex[v.catid],
						value: 1,
						type: 'depends'
					}))
				];
			}

			return { nodes, links };
		}
	},
	mutations: {
		filterSelection(state, filterSelection) {
			state.filterSelection = filterSelection;
		}
	},
	actions: {
		async downloadGraph() {
			saveSvgAsPng(document.querySelector('#graph'), 'graph.png');
		}
	}
};

export default GraphModule;
