import { FileType, MarkType } from '@/types/sqlite';

function getStartChange(oldText: string, newText: string) {
	for (let i = 0; i < oldText.length; i++) {
		if (oldText[i] != newText[i]) return i - 1;
	}
	return oldText.length;
}

function getEndChange(oldText: string, newText: string, startChange: number) {
	for (
		let i = oldText.length - 1, j = newText.length - 1;
		i > startChange - 2;
		i--, j--
	) {
		if (j < 0 || oldText[i] != newText[j]) return i + 1;
	}
	return 0;
}

interface MarkingsChanged {
	updated: MarkType[][];
	removed: MarkType[];
	change: number;
}

export function calculateChangedMarkings(
	file: FileType,
	newText: string,
	markings: MarkType[] // Should be from same file and not deleted (mark.fid === file.id && mark.status === 1)
): MarkingsChanged {
	const markingsChanged: MarkingsChanged = {
		updated: [],
		removed: [],
		change: 0
	};
	const startChange = getStartChange(file.file, newText);
	if (startChange === newText.length) {
		return markingsChanged;
	}
	const endChange = getEndChange(file.file, newText, startChange);
	const change = newText.length - file.file.length;
	markingsChanged.change = change;
	markings.forEach((v) => {
		if (v.selend <= startChange) return; // Markings before change
		if (v.selfirst >= endChange) {
			// Markings after change
			markingsChanged.updated.push([
				v,
				{
					...v,
					selfirst: v.selfirst + change,
					selend: v.selend + change
				}
			]);
			return;
		}
		if (v.selend > endChange && v.selfirst < startChange) {
			// Markings inside the change
			markingsChanged.updated.push([
				v,
				{
					...v,
					selend: v.selend + change,
					seltext: newText.substr(v.selfirst, v.selend + change - v.selfirst)
				}
			]);
			return;
		}
		if (v.selend <= endChange && v.selfirst > startChange) {
			// Markings within the change
			markingsChanged.removed.push(v);
			return;
		}
	});
	return markingsChanged;
}
