import { SqlJs } from 'sql.js/module';

export function updateCodingTablesWithId(database: SqlJs.Database | null) {
	database!.run(`
create table coding_tmp
(
	id integer
		constraint coding_pk
		primary key autoincrement,
	cid integer,
	fid integer,
	seltext text,
	selfirst real,
	selend real,
	status integer,
	owner text,
	date text,
	memo text
);

insert into coding_tmp(cid, fid, seltext, selfirst, selend, status, owner, date, memo) select cid, fid, seltext, selfirst, selend, status, owner, date, memo from coding;

drop table coding;

alter table coding_tmp rename to coding;
`);
}

export function updateCodingTablesWithoutId(database: SqlJs.Database | null) {
	database!.run(`
create table coding_tmp
(
	cid integer,
	fid integer,
	seltext text,
	selfirst real,
	selend real,
	status integer,
	owner text,
	date text,
	memo text
);

insert into coding_tmp(cid, fid, seltext, selfirst, selend, status, owner, date, memo) select cid, fid, seltext, selfirst, selend, status, owner, date, memo from coding;

drop table coding;

alter table coding_tmp rename to coding;
`);
}
export function updateCategoryTablesWithParentId(
	database: SqlJs.Database | null
) {
	database!.run(`
alter table codecat
add parentid int default null;
`);
}

export function updateCategoryTablesWithoutParentId(
	database: SqlJs.Database | null
) {
	database!.run(`
create table codecat_tmp
(
	name text,
	cid integer,
	catid integer,
	owner text,
	date text,
	dateM text,
	memo text,
	status integer
);

insert into codecat_tmp(name, cid, catid, owner, date, dateM, memo, status) select name, cid, catid, owner, date, dateM, memo, status from codecat;

drop table codecat;

alter table codecat_tmp rename to codecat;
`);
}
